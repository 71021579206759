import React from 'react';
import {Router, Route, Switch} from 'dva/router';
import Dynamic from 'dva/dynamic';
import PageLayout from "./layout/page-layout";
import routers from './router-config';
import PageFooter from "./components/page-footer";
import PageHeader from "./components/page-header";
import zhCN from 'antd/es/locale/zh_CN';
import {ConfigProvider} from 'antd';
function RouterConfig({history, app}) {
    return (
        <ConfigProvider locale={zhCN}>
        <div className='page-box-wrap'>
            <PageHeader history={history}/>
            <Router history={history}>
                <PageLayout app={app}>
                    <Switch>
                        {
                            routers.map(({path, title, isDefault, ...route}, index) => (
                                <Route
                                    exact
                                    key={index}
                                    path={path}
                                    component={Dynamic({
                                        app,
                                        ...route,
                                    })}
                                />
                            ))
                        }
                    </Switch>
                </PageLayout>
            </Router>
            <PageFooter history={history}/>
        </div>
        </ConfigProvider>
    );
}

export default RouterConfig;
