import dva from 'dva';
import {createBrowserHistory} from 'history';
import "./assets/css/basic.scss";
// 消息提示框时间
window.ToastDuration = 1.5;
// 1. Initialize
const app = dva({
    history: createBrowserHistory(),
});
// 2. Plugins
// app.use({});

// 3. Model
app.model(require('./models/app-model').default);
app.model(require('./models/goods-base-model').default);

// 4. Router
app.router(require('./router').default);

// 5. Start
app.start('#YunShl');
