import React from 'react'
import classnames from 'classnames'
import CommonUtil from "../../../utils/common-util";
import {LocalConst} from "../../../utils/constant";
import ChatContent from "./chat-item-content";
import {Avatar} from 'antd'
import dayjs from 'dayjs'

const ChatItem = ({data, avatar, downloadFile, onPress, audioPlayIndex, toCustomMsgDetail}) => {

    data.content = CommonUtil.stringToObj(data.content);
    const avatarDom = <div className={'avatar-box'}>
        <img src={avatar || LocalConst.AvatarDefault} alt=""/>
    </div>;


    const isLeft = data.flow === 'in';
    const isRight = data.flow === 'out';
    const isCustom = data.type === 'custom';
    const isCustomSend = isCustom && (/*兼容官方demo的类型*/data.content.type !== 3 && data.content.type !== "chartlet")
    const chatItemMainCls = classnames(`chat-item-box`, {
        'chat-item-main-left tl': isLeft && !isCustomSend,
        'chat-item-main-right tr': isRight && !isCustomSend,
        'chat-item-main-custom tc': isCustomSend
    });
    return (
        <div className={chatItemMainCls}
        >
            {
                isLeft && !isCustomSend &&
                <Avatar size={48} icon={avatarDom}/>
            }
            <div className={`chat-item-main `}>
                <div className="chat-info-box">
                    {/*{data.fromNick} &nbsp;&nbsp;*/}
                    {dayjs(data.time).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div className="chat-info-content">
                    <ChatContent
                        data={data}
                        downloadFile={downloadFile}
                        onPress={onPress}
                        audioPlayIndex={audioPlayIndex}
                        toCustomMsgDetail={toCustomMsgDetail}
                    />
                </div>
            </div>
            {
                isRight && !isCustomSend &&
                <Avatar size={48} icon={avatarDom}/>
            }
        </div>
    )


};
export default ChatItem
