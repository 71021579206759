import React from 'react';
import {connect} from 'dva';
import {withRouter} from 'dva/router';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import routers from './../router-config';
import pathToRegexp from 'path-to-regexp'
import classNames from 'classnames'
import UserMenu from "../components/user-menu";
import IMChat from "../components/im";

const PageLayout = ({appModel, children, dispatch, location: {pathname}, ...props}) => {
    // 获取页面设置
    const page = (routers || []).find(router => pathToRegexp(router.path).exec(pathname)) || {};
    const cls = classNames('page-body', {'page-max': page.layoutMax});
    // 获取页面设置
    return (
        <div className={cls}>
            {
                page.showMenu &&
                <UserMenu pathname={pathname} history={props.history} dispatch={dispatch}/>
            }
            <div className="page-main">{children}</div>
        </div>

    );
};

const mapStateToProps = (state) => {
    return {...state}
};
export default withRouter(connect(mapStateToProps)(PageLayout));
