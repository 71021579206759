import React, {Fragment, useEffect, useState, useCallback} from "react";
import classnames from "classnames";
import './styles.scss'
import emojiObj from "../../../../utils/emoji";
import {SmileOutlined} from '@ant-design/icons';
import {Popover} from 'antd'

function genEmojiList(type, emojiList) {
    let result = {};
    for (let name in emojiList) {
        let emojiMap = emojiList[name];
        let list = [];
        for (let key in emojiMap) {
            list.push({
                type,
                name,
                key,
                img: emojiMap[key].img
            })
        }
        if (list.length > 0) {
            result[name] = {
                type,
                name,
                list,
                album: list[0].img
            }
        }
    }
    return result
}


const EmojiWrap = ({dispatch, chooseEmoji, sendFunc}) => {
    const [newEmojiList, setNewEmojiList] = useState({});
    const [emojiKeysList, setEmojiKeys] = useState([]);
    const [currentEmojiList, setCurrentEmojiList] = useState({});
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        let emoji = genEmojiList('emoji', emojiObj.emojiList);
        let pinup = genEmojiList('pinup', emojiObj.pinupList);
        const emojiList = Object.assign({}, emoji, pinup);
        const emojiKeys = [];
        for (let key in emojiList) {
            emojiKeys.push(key)
        }
        const currentEmoji = emojiList[emojiKeys[0]];
        setNewEmojiList(emojiList);
        setEmojiKeys(emojiKeys);
        setCurrentEmojiList(currentEmoji)
    }, [dispatch]);

    useEffect(() => {
        callback()
    }, []);

    const callback = useCallback(() => {
        document.addEventListener('click', (e) => {
            e&&e.stopPropagation();
            setVisible(false)
        }, false)
    }, []);

    const chooseItem = (e, item) => {
        if (item.name === 'emoji') {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            chooseEmoji({...item, clickNow: Date.now()});
        } else {
            const content = {"type": 'chartlet', "data": {"catalog": item.name, "chartlet": item.key}};
            const options = {
                scene: 'p2p',
                content: JSON.stringify(content),
                type: 'custom',
            };
            sendFunc(options)
        }

    };

    const genContent = () => {
        return <div className="emoji-box">
            <div className="emoji-body-box">
                {
                    (currentEmojiList.list || []).map((item, index) => {
                        const cls = classnames('emoji-item', {
                            'size': item.type !== 'emoji'
                        });
                        return (
                            <Popover placement="top"
                                     key={item.key + index}
                                     content={<img src={item.img} alt={item.key} className={'emoji-preview'}/>}
                                     trigger="hover"
                            >
                                <span className={cls}
                                      key={item.key + index}
                                      role={'none'}
                                      onClick={(e) => chooseItem(e, item)}
                                >
                                    <img src={item.img} alt={item.key}/>
                                </span>
                            </Popover>

                        )

                    })
                }
            </div>
            <div className="emoji-tabs-box">
                {
                    emojiKeysList.map(key => {
                        const cls = classnames('emoji-album-item ', {
                            'active': currentEmojiList.name === key
                        });
                        return <span className={cls} key={key} role={'none'} onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            setCurrentEmojiList(newEmojiList[key])
                        }}>
                                <img src={newEmojiList[key].album} alt=""/>
                            </span>
                    })
                }
            </div>
        </div>
    };

    return (
        <Fragment>
            <Popover placement="top"
                     autoAdjustOverflow
                     arrowPointAtCenter
                     content={genContent}
                     getPopupContainer={() => document.querySelector('#jsEmojiItemBox')}
                     trigger="click"
                     visible={visible}
            >
                <span className={'item'}
                      id={'jsEmojiItemBox'}
                      onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          setVisible(!visible)
                      }}
                      role={'none'}
                >
                    <SmileOutlined/>
                </span>
            </Popover>
        </Fragment>
    )
};

export default EmojiWrap
