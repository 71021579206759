import React, {Fragment, useState} from "react";
import {ProfileOutlined} from '@ant-design/icons';
import {Popover} from 'antd'
import './styles.scss'
const usefulExpressions = [
    '今天能发货吗？拜托今天一定帮我发出',
    '要原装原包的',
    '要产品图片',
    '要提供产品图片确认',
    '要提供车头车尾图片确认',
];

const UsefulExpressions = ({sendFunc}) => {

    const [visible, setVisible] = useState(false);

    const genContent = () => {
        return (
            <div className={'useful-expressions-box'}>
                <dl>
                    <dt>常用语</dt>
                    {
                        usefulExpressions.map((item, index) => {
                            return <dd key={index} role={'none'} onClick={() => {
                                const options = {
                                    scene: 'p2p',
                                    value: item,
                                    type: 'text',
                                };
                                sendFunc(options);
                                setVisible(false)
                            }}>{item}</dd>
                        })
                    }
                </dl>
            </div>
        )
    };

    return (
        <Fragment>
            <Popover placement="top"
                     autoAdjustOverflow
                     arrowPointAtCenter
                     content={genContent}
                     getPopupContainer={() => document.querySelector('#jsUsefulExpressionsItemBox')}
                     trigger="click"
                     visible={visible}
            >
                <span className={'item'}
                      id={'jsUsefulExpressionsItemBox'}
                      onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          setVisible(!visible)
                      }}
                      role={'none'}
                >
                 <ProfileOutlined/>
                </span>
            </Popover>
        </Fragment>
    )
};

export default UsefulExpressions
