const routers = [
    {
        title:'首页',
        path: '/',
        layoutMax: true,
        component: () => import('./routes/home/home-route'),
    },
    {
        title:'首页',
        path: '/home/:id?',
        layoutMax: true,
        component: () => import('./routes/home/home-route'),
    },
    {
        title:'微页面',
        path: '/page/:id', // 404
        layoutMax: true,
        component: () => import('./routes/home/page-route'),
    },
    {
        title:'立即询价',
        path: '/inquire',    //立即询价
        component: () => import('./routes/inquire/inquire-route'),
    },
    {
        title:'询价单列表',
        path: '/inquire/list',   //询价单列表
        showMenu: true,
        component: () => import('./routes/inquire/inquiry-list-route'),
    },
    {
        title:'询价单详情',
        path: '/inquire/detail/:id',  //询价单详情
        component: () => import('./routes/inquire/inquiry-detail-route'),
    },
    {
        title:'求购单',
        path: '/buying/list',   //求购单
        showMenu: true,
        component: () => import('./routes/inquire/buying-list-route'),
    },
    {
        title:'商品列表',
        layoutMax: true,
        path: '/goods/list', //商品列表
        // models: () => [import('./models/goods-base-model')],
        component: () => import('./routes/goods/goods-list-route'),
    },
    {
        title:'商品详情',
        path: '/goods/detail/:id/:productId?', //商品详情
        // models: () => [import('./models/goods-base-model')],
        component: () => import('./routes/goods/goods-detail-route'),
    },
    {
        title:'商品列表二级页',
        layoutMax: true,
        path: '/goods/list/label', //商品列表二级页
        component: () => import('./routes/goods/goods-list-label-route'),
    },
    {
        title:'购物车',
        path: '/cart', //购物车
        models: () => [import('./models/cart/cart-model')],
        component: () => import('./routes/cart/cart-route'),
    },
    {
        title:'订单确认',
        path: '/order/create', // 订单确认
        component: () => import('./routes/order/order-create-route'),
    },
    {
        title:'支付结果页',
        path: '/order/pay/result/:id?', //支付结果页
        component: () => import('./routes/order/order-pay-result-route'),
    },
    {
        title:'支付页',
        path: '/order/pay/:id?',//支付页
        component: () => import('./routes/order/order-pay-route'),
    },
    {
        title:'订单列表',
        path: '/order/list', // 订单列表
        showMenu: true,
        models: () => [import('./models/order/order-model')],
        component: () => import('./routes/order/order-list-route.js'),
    },
    {
        title:'订单详情',
        path: '/order/detail/:id', // 订单详情
        models: () => [import('./models/order/order-model')],
        component: () => import('./routes/order/order-detail-route.js'),
    },
    {
        title:'订单日志',
        path: '/order/log', // 订单日志
        component: () => import('./routes/order/order-log-route.js'),
    },
    {
        title:'个人中心',
        path: '/user', // 个人中心
        showMenu: true,
        component: () => import('./routes/user/user-route.js'),
    },
    {
        title:'对账明细',
        path: '/deposit/detail', // 对账明细
        showMenu: true,
        component: () => import('./routes/user/deposit-details-route'),
    },
    {
        title:'账户信息',
        path: '/account', // 账户信息
        showMenu: true,
        component: () => import('./routes/user/account-route'),
    },
    {
        title:'收货地址',
        path: '/address', // 收货地址
        showMenu: true,
        component: () => import('./routes/user/address-route'),
    },
    {
        title:'通知公告列表',
        path: '/notices/list', // 通知公告列表
        showMenu: true,
        component: () => import('./routes/user/notices-route'),
    },
    {
        title:'通知公告详情',
        path: '/notices/detail/:id', // 通知公告详情
        component: () => import('./routes/user/notices-detail-route'),
    },
    {
        title:'订单消息',
        path: '/order/message', // 订单消息
        showMenu: true,
        component: () => import('./routes/user/order-message-route'),
    },
    {
        title:'员工管理',
        path: '/staff/list', // 员工管理
        showMenu: true,
        component: () => import('./routes/user/staff-route'),
    },
    {
        title:'退货单',
        path: '/refund/list', // 退货单
        showMenu: true,
        component: () => import('./routes/refund/refund-list-route'),
    },
    {
        title:'退货单详情',
        path: '/refund/detail/:id', // 退货单详情
        component: () => import('./routes/refund/refund-detail-route'),
    },
    {
        title:'帮助详情',
        path: '/help/detail', // 帮助详情
        component: () => import('./routes/user/help-detail-route'),
    },
    {
        title:'收藏列表',
        path: '/collect/list', // 收藏列表
        component: () => import('./routes/user/collect-list-route'),
    },
    {
        title:'常用清单',
        path: '/keep/list', // 常用清单
        component: () => import('./routes/user/keep-list-route'),
    },
    {
        title:'授信管理',
        path: '/credit/quota', // 授信管理
        showMenu: true,
        component: () => import('./routes/user/credit-quota-route'),
    },
    {
        title:'优惠券',
        path: '/coupon/list', // 优惠券
        showMenu: true,
        component: () => import('./routes/user/coupon-list-route'),
    },
    {
        title:'领券中心',
        path: '/coupon/center', // 领券中心
        showMenu: false,
        layoutMax: true,
        component: () => import('./routes/user/coupon-center-route'),
    },
    {
        title:'404',
        path: '*', // 404
        component: () => import('./routes/other/404-route'),
    },

];

export default routers;

