// 我的名片
function onMyInfo(user, data) {
    console.log('收到我的名片', user);
    data.myInfo = user;

    updateMyInfoUI(user);
}

// 更新我的名片
function onUpdateMyInfo(user, data) {
    console.log('我的名片更新了', user);
    data.myInfo = window.nim.util.merge(data.myInfo, user);
    updateMyInfoUI(user);
}

// 刷新我的名片
function updateMyInfoUI(user) {
    // 刷新界面
}

// 好友名片
function onUsers(users, data) {
    console.log('收到用户名片列表', users);
    data.users = window.nim.mergeUsers(data.users, users);
}

// 更新好友名片
function onUpdateUser(user, data) {
    console.log('用户名片更新了', user);
    data.users = window.nim.mergeUsers(data.users, user);
}

export {
    onMyInfo,
    onUpdateMyInfo,
    onUsers,
    onUpdateUser
}
