import React, {Fragment} from 'react';
import classnames from 'classnames'
import {connect} from "dva";
import QRCode from 'qrcode.react';
import {Popover, Input, Badge, message, Affix} from 'antd'
import {Scrollbars} from 'react-custom-scrollbars';
import qs from 'qs';
import './index.scss';
import ImgAdapt from "../img-adapt";
import StorageUtil from "../../utils/storage-util";
import {LocalConst} from "../../utils/constant";
import IMGDownloadQrCode from '../../assets/images/app-download-qrcode.png';
import {EnquiryFetch, SystemFetch} from "../../services/service";
import CommonUtil from "../../utils/common-util";
import RenderUtil from "../../utils/render-util";
import UrlUtil from "../../utils/url-config";
import SvgIcon from "../svg-icon";
import {MessageOutlined} from '@ant-design/icons'
import {createPortal} from 'react-dom';
import IMChat from "../im";

const IMGLogo = "https://img.ysdinghuo.com/361@jyNZuh@shop-logo.png";

@connect(state => ({...state.goodsBaseModel, ...state.appModel}))
class PageHeader extends React.Component {
    constructor(props) {
        super(props);
        const {history} = props;
        const query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
        this.state = {
            keywordList: [],
            keyword: query.keyword_ || '',
            classifyList: [],
            isFixationHead: false,
            letter: 'all',
            cartCount: StorageUtil.getItem(LocalConst.cartCount),
            vinLogList: [],
            vin: ''
        };
        this.companyId = StorageUtil.getItem(LocalConst.companyId);
        this.companyData = StorageUtil.getItem(LocalConst.companyData);
        this.isLogin = CommonUtil.isLogin();
        this.user = StorageUtil.getItem(LocalConst.mShop_user) || {}
        this.ysClient = StorageUtil.getItem(LocalConst.ysClient) || {}

        this.bodyNode = document.querySelector('body')
    }

    componentDidMount() {
        this.searchClientKeyWordList();
        this.getClientSearchVinLong();
        this.pageScrollBox = document.querySelector('body');
        this.pageScrollBox.addEventListener('scroll', () => this.handleScroll());
        // 更新购物车
        window.addEventListener('setItemEvent', (e) => {
            if (e.key === LocalConst.cartCount) {
                this.setState({
                    cartCount: JSON.parse(e.newValue)
                })
            }
        });

        document.addEventListener('click', e => {
            this.hideSearchLog();
            this.setState({
                isShowSearchDropdown: false
            })
        }, false)

    }

    handleScroll = () => {
        const {pageHeader = {}} = this.props;
        const {banner_ = {}} = pageHeader;
        const {height_ = 0} = banner_;
        this.setState({
            isFixationHead: this.pageScrollBox.scrollTop > (64 + height_)
        });
    };

    searchClientKeyWordList = () => {
        if (!this.isLogin) return;
        var param = {
            company_id_: this.companyId,
            showCount: 10
        };
        SystemFetch.searchClientKeyWordList(param, (rs) => {
            if (rs.status === 1) {
                this.setState({
                    keywordList: rs.data.pdList || []
                })
            }
        });
    };

    searchKeyWordList = () => {
        const {keyword} = this.state;
        const param = {
            company_id_: this.companyId,
            keyword_: keyword,
            showCount: 10
        };
        SystemFetch.searchKeyWordList(param, (rs) => {
            if (rs.status === 1) {
                this.setState({
                    keywordList: rs.data.pdList || []
                })
            }
        });
    };

    selectKeywordLog = (e, item) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.searchKeyword(item.keywords_);
        this.setState({
            keyword: item.keywords_
        });


    };
    changeKeyword = (e) => {
        this.setState({
            keyword: e.target.value
        }, () => {
            const {keyword} = this.state;
            if (keyword.length > 0 && this.isLogin) {
                this.searchKeyWordList()
            } else {
                this.searchClientKeyWordList()
            }
        })
    };
    searchKeyword = (keyword) => {
        const {history} = this.props;
        const path = `/goods/list?keyword_=${keyword}`;
        if (history.location.pathname === '/goods/lis') {
            history.replace(path);
        } else {
            history.push(path);
        }
    };
    // 删除客户指定的搜索关键词
    toDeleteKeywordsById = (e, id_) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        SystemFetch.toDeleteKeywordsById(id_, (rs) => {
            if (rs.status) {
                message.success(rs.message)
                this.searchClientKeyWordList()
            } else {
                message.error(rs.message)
            }
        });
    };
    // 删除客户所有的搜索关键词
    toDeleteClientAllKeywords = () => {
        SystemFetch.toDeleteClientAllKeywords(null, (rs) => {
            if (rs.status) {
                message.success(rs.message)
                this.searchClientKeyWordList()
            } else {
                message.error(rs.message)
            }
        });
    };


    getClassifyByDecorateName = (key, navigation) => {
        let name = '';
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                if (item.classify_type_ === key) {
                    name = item.name_
                }
            });
        }
        return name
    };
    getClassifyByDecorateSeq = (key, navigation) => {
        let seq = '';
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                if (item.classify_type_ === key) {
                    seq = item.seq_
                }
            });
        }

        return seq
    };
    getClassifyByDecorateHidden = (key, navigation) => {
        let hidden = 0;
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                if (item.classify_type_ === key) {
                    hidden = item.hidden_
                }
            });
        }
        return hidden
    };
    handlePropertyByDecorateSort = (key, list, navigation) => {
        let propertyList = [];
        if (navigation && navigation.classify_list_) {
            navigation.classify_list_.forEach(item => {
                list.forEach(l => {
                    if (item.classify_type_ === key && item.property_id_ === l.id_) {
                        l.type_ = 'property_' + l.id_;
                        l.hidden_ = item.hidden_;
                        l.seq_ = item.seq_;
                        l.children = item.children || [];
                        propertyList.push(l)
                    }
                })
            });
        }
        return propertyList
    };
    menuItemMouseEntre = (e) => {
        e.stopPropagation();
        const dataId = e.target.getAttribute('data-id');
        const selectItem = document.querySelector(`#jsSelect_${dataId}`);
        const menuActive = document.querySelectorAll(`.menu-active`);
        if (menuActive.length > 0) {
            menuActive.forEach(item => {
                const menuActiveId = document.querySelector(`#${item.id}`);
                if (`#jsSelect_${dataId}` !== `#${item.id}`) {
                    menuActiveId.classList.remove('menu-active')
                }
            })
        }
        if (selectItem) {
            selectItem.classList.add('menu-active')
        }
    };
    menuItemMouseLeave = () => {
        const menuActive = document.querySelectorAll(`.menu-active`);
        if (menuActive.length > 0) {
            menuActive.forEach(e => {
                const menuActiveId = document.querySelector(`#${e.id}`);
                menuActiveId.classList.remove('menu-active')
            })
        }
    };
    renderMenuItem = (sub, subIndex) => {
        return (
            <dl key={subIndex}>
                <dt onClick={() => this.selectQuery('type', sub)} role={'none'}>
                    {sub.name || sub.name_}
                </dt>
                {
                    (sub.children || []).map((i, sIndex) => {
                        return (
                            <dd key={sIndex} onClick={() => this.selectQuery('type', i)} role={'none'}>
                                {i.name}
                                {
                                    sIndex < sub.children.length - 1 &&
                                    <i className="short-line"/>
                                }
                            </dd>
                        )
                    })
                }
            </dl>
        )
    };
    selectLetter = (letter) => {
        this.setState({
            letter: letter
        })
    };
    renderMenuSubBoxList = (item, index) => {
        switch (item.type_) {
            case "type":
                let renderMenuItem = [];
                (item.children || []).forEach((sub, subIndex) => {
                    renderMenuItem.push(this.renderMenuItem(sub, subIndex))
                });
                return renderMenuItem || [];
            case "brand":
                const {letter} = this.state;
                return <div className='menu-brand-item-box' key={index}>
                    <div className="first-letter-box">
                        <span className={'all' === letter ? 'letter active' : 'letter'} role='none'
                              onClick={() => this.selectLetter('all')}>全部品牌</span>
                        {
                            item.keyArr.map(l => {
                                return (
                                    <span className={l === letter ? 'letter active' : 'letter'} role='none'
                                          onClick={() => this.selectLetter(l)} key={l}>{l}</span>
                                )
                            })
                        }
                    </div>
                    <div className="brand-item-box-main">
                        {
                            item.brandList[letter].map((brand, bIndex) => {
                                return (
                                    <div className="brand-box-card" key={bIndex}
                                         onClick={() => this.selectQuery(item.type_, brand)} role={'none'}>
                                        <div className="img-box">
                                            {
                                                brand.img_ ? (
                                                    <img src={brand.img_ + '_100'} alt=""/>
                                                ) : (
                                                    <span title={brand.name_}>{brand.name_}</span>
                                                )
                                            }
                                        </div>
                                        <div className="info-box">
                                            <div className="title-box">
                                                <p className="title ellipsis-2" title={brand.name_}>{brand.name_}</p>
                                                <p className="tips ellipsis-2"
                                                   title={brand.introduce_}>{brand.introduce_}</p>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })

                        }
                    </div>
                </div>
                    ;
            default:
                return (
                    <div className='nav-service-item-box' key={index}>
                        {
                            item.children.map((sub, subIndex) => {
                                return (
                                    <div className="item-box-card"
                                         key={subIndex}
                                         role={'none'}
                                         onClick={() => this.selectQuery(item.type_, sub)}
                                    >
                                        <span title={sub.name || sub.name_}>{sub.name || sub.name_}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
        }
    };
    handleClassifyList = (data, navigation) => {
        const {typeList, brandList, tagList, propertyList} = data;
        let results = [];
        if (typeList.length > 0) {
            let typeData = {};
            typeData.name = this.getClassifyByDecorateName(1, navigation);
            typeData.hidden_ = this.getClassifyByDecorateHidden(1, navigation);
            typeData.seq_ = this.getClassifyByDecorateSeq(1, navigation);

            typeData.children = typeList;
            typeData.type_ = 'type';
            typeData.id_ = typeData.type_;
            results.push(typeData)
        }
        if (brandList.length > 0) {
            let brandData = {};
            let brandList_ = {};
            let children = [];
            brandList.forEach(brand => {
                if (brand.is_view_shop_) {
                    children.push(brand);
                    if (!brandList_[brand.first_letter_]) {
                        brandList_[brand.first_letter_] = [];
                    }
                    brandList_[brand.first_letter_].push(brand)
                }
            });

            brandData.keyArr = Object.keys(brandList_).sort();
            brandData.brandList = {all: children, ...brandList_};
            brandData.name = this.getClassifyByDecorateName(3, navigation);
            brandData.hidden_ = this.getClassifyByDecorateHidden(3, navigation);
            brandData.seq_ = this.getClassifyByDecorateSeq(3, navigation);
            brandData.children = children;
            brandData.type_ = 'brand';
            brandData.id_ = brandData.type_;
            results.push(brandData)
        }
        if (tagList.length > 0) {
            let tagData = {};
            tagData.name = this.getClassifyByDecorateName(2, navigation);
            tagData.hidden_ = this.getClassifyByDecorateHidden(2, navigation);
            tagData.seq_ = this.getClassifyByDecorateSeq(2, navigation);

            tagData.children = tagList;
            tagData.type_ = 'tag';
            tagData.id_ = tagData.type_;
            results.push(tagData)
        }
        if (propertyList.length > 0 && (navigation && navigation.classify_list_ && navigation.classify_list_.find(item => item.classify_type_ === 4))) {
            let propertyDate = this.handlePropertyByDecorateSort(4, propertyList, navigation);
            results = results.concat(propertyDate)
        }
        let arr = [];
        let classifyList = [];
        results.forEach(item => {
            if (item && item.type_ === 'type') {
                classifyList = item.children
            }
            arr.push(item)
        });
        if (navigation && navigation.type_tree_ && navigation.type_tree_.type_) {
            classifyList = arr
        }

        classifyList = classifyList.sort((a, b) => {
            return a.seq_ - b.seq_
        });
        return classifyList
    };
    navClick = (btn) => {
        const {history} = this.props;
        if (!btn.newLocation) return;
        if (btn.newLocation.checkLogin) {
            RenderUtil.renderLoginModal();
            return;
        }
        if (btn.target_.type_ === 8) {
            window.location.href = btn.target_.val_;
            return;
        }
        history.push(btn.newLocation.pathname);
        this.forceUpdate();
    };
    selectQuery = (type, item) => {
        const {history} = this.props;
        const query = {};
        switch (type) {
            case 'type':
                Object.assign(query, {type_: item.all_ids_});
                break;
            case 'tag':
                Object.assign(query, {tag_: item.id_});
                break;
            case 'brand':
                Object.assign(query, {brand_: item.id_});
                break;
            default: // 属性
                console.log('selectQuery', query);
        }
        // 关闭
        this.menuItemMouseLeave();
        const path = `/goods/list?${qs.stringify(query)}`;
        if (history.location.pathname === '/goods/list') {
            history.replace(path);
        } else {
            history.push(path);
        }
    };
    toPage = (pathname) => {
        if (!pathname) return;
        if (!this.isLogin) {
            this.toLogin();
            return;
        }
        this.props.history.push(pathname)
    };
    toLogin = () => {
        const companyId = StorageUtil.getItem(LocalConst.companyId);
        window.location.href = `${UrlUtil.getAccountUrl(companyId)}/login`;
    };

    //切换账号
    exchangeAccount = () => {
        SystemFetch.getAuthCode(null, rs => {
            if (rs.status !== 1) {
                message.error(rs.message);
                return;
            }
            if (this.isExperience) {
                // 体验账号切换到管理端
                CommonUtil.toMgrExperience();
            } else {
                const host = UrlUtil.getAccountUrl();
                window.location.href = `${host}/company?currentUser=${this.user.login_}&authCode=${rs.data}`
            }
        })

    };
    toLink = (e, target, name) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (typeof target === "undefined" || !target.type_) return;
        switch (target.type_) {
            default:
                const newLocation = CommonUtil.getDecorateLocation(target);
                this.props.history.push({pathname: newLocation.pathname, state: newLocation.query});
        }
    };

    handleAffixItem = item => {
        const companyData = StorageUtil.getItem(LocalConst.companyData) || {};
        const {broadside_ = {}} = this.props;
        switch (item.type_) {
            case 1:
                const url = `${UrlUtil.getMShopUrl()}/?companyId=${companyData.id_}`;
                return (
                    <div className="qrcode-box">
                        <div className="img-box">
                            <QRCode size={136} value={url}/>
                            <p className="txt">微信扫一扫，订货更便捷</p>
                        </div>
                        {
                            companyData.wx_public_img_ &&
                            <div className="img-box">
                                <img src={companyData.wx_public_img_} alt=""/>
                                <p className="txt">关注微信公众号</p>
                            </div>
                        }
                    </div>
                );
            case 2:
                return (
                    <div className="service-box">
                        <dl>
                            <dt>服务热线</dt>
                            <dd>
                                <SvgIcon href="icon-service-phone"/>
                                <span className="tel">{broadside_.service_tel_}</span>
                            </dd>
                        </dl>
                        {
                            broadside_.service_list_ && broadside_.service_list_.length > 0 &&
                            <dl>
                                <dt>在线客服</dt>
                                {
                                    (broadside_.service_list_ || []).map((service, index) => {
                                        return (
                                            <dd key={index}>
                                                <a className="service-QQ"
                                                   href={`http://wpa.qq.com/msgrd?v=3&uin=${service.service_qq_}&site=qq&menu=yes`}
                                                   target="_blank" rel="noopener noreferrer">
                                                    <SvgIcon href='icon-service-qq'/>
                                                    <span className="txt">{service.service_name_}</span>
                                                </a>
                                            </dd>
                                        )
                                    })
                                }

                            </dl>
                        }
                    </div>
                );
            default:
                return (
                    <div>{item.title_}</div>
                );
        }
    };

    affixOnClick = item => {
        if (!item) {
            const {dispatch, imCurrentFriend, users} = this.props
            if (!this.props.ImSync) return;
            if (!users?.find(user => user.account === imCurrentFriend.account)) {
                CommonUtil.imAddFriend({dispatch, oldFriends: users, account: imCurrentFriend.account});
            }
            dispatch({
                type: 'appModel/saveState',
                payload: {
                    visibleChat: true
                }
            });
            return;
        }
        switch (item.type_) {
            case 1:
            case 2:
                return;
            case 3:
                this.toPage('/order/list');
                break;
            case 4:
                document.body.scrollTop = 0;
                break
            // no default

        }
    };

    toggleSearchDropdown = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({
            isShowSearchDropdown: !this.state.isShowSearchDropdown
        })
    };

    //获取vin码查询记录
    getClientSearchVinLong = () => {
        if (!this.isLogin) return;
        EnquiryFetch.getClientSearchVinLong({
            showCount: 10,
            client_id_: this.user.client_id_,
            vin_search_type_: 2,
            orderby: 'create_time_',
            ascending: 'desc'
        }, rs => {
            if (rs.status) {
                this.setState({
                    vinLogList: rs.data.pdList
                })
            }
        })
    };

    //vin码输入
    changevin = (e) => {
        const val = e.target.value.replace(/[^0-9A-Za-z]/, '');
        this.setState({
            vin: val,
        })
    };
    //显示vin码搜索记录
    showSearchLog = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({
            isShowSearchLog: true
        }, this.getClientSearchVinLong)
    };
    //隐藏vin码搜索记录
    hideSearchLog = () => {
        setTimeout(() => {
            this.setState({
                isShowSearchLog: false
            })
        }, 200)
    };
    //选择搜索记录
    selectLogItem = (item) => {
        this.setState({
            vin: item.vin_,
        }, () => this.searchVin())
    };
    //删除搜索记录
    delLogItem = (e, item) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        EnquiryFetch.deleteSearchVinLongById(item.id_, rs => {
            if (rs.status) {
                message.success(rs.message);
                this.getClientSearchVinLong();
            } else {
                message.error(rs.message);
            }
        })
    };
    //vin码搜索
    searchVin = (e) => {
        if (!this.isLogin) {
            RenderUtil.renderLoginModal();
            return;
        }
        if (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }
        const {vin} = this.state;
        if (vin.length < 7) return;
        if (!vin) {
            message.warning('请输入VIN码！', 1);
            return
        }
        this.props.history.push({pathname: '/inquire', state: {vin, from: 'home'}})

    };

    render() {
        const {
            keywordList, keyword, isFixationHead,
            cartCount, isShowSearchDropdown, vinLogList,
            vin, isShowSearchLog
        } = this.state;
        const {
            pageHeader: {logo_ = {}, banner_ = {}, background_ = {}, navigation_ = {}},
            decorateInfo: {is_working_ = null, company_wx_public_img_ = null},
            broadside_ = {}, typeList, brandList, tagList, propertyList, history,
            imCurrentFriend, sessions = [], visibleChat
        } = this.props;
        const sideAffix = (broadside_.fixbar_list_ || []).filter(item => !item.hidden_);
        const backgroundStyle = {
            'backgroundColor': background_.color_,
            'backgroundPositionX': background_.horizontal_,
            'backgroundPositionY': background_.vertical_,
            'backgroundRepeat': background_.repeat_ === 'padding' ? 'no-repeat' : background_.repeat_,
            'backgroundSize': background_.repeat_ === 'padding' ? '100% 100%' : '',
        };
        if (background_.img_) {
            backgroundStyle['backgroundImage'] = 'url(' + background_.img_ + ')'
        }
        const headerBannerStyle = {
            'height': (banner_.height_ || 0) + 'px',
        };
        const classifyStyle = {
            'height': navigation_.type_tree_ && navigation_.type_tree_.height_ + 'px',
            'backgroundColor': navigation_.background_color_ ? CommonUtil.colorRgb(navigation_.background_color_, navigation_.background_alpha_) : ''
        };
        const classifyFontStyle = {
            'color': navigation_.font_color_ ? CommonUtil.colorRgb(navigation_.font_color_, navigation_.font_alpha_) : '',
            'borderColor': navigation_.font_color_ ? CommonUtil.colorRgb(navigation_.font_color_, 10) : '',
        };

        const pathname = window.location.pathname;
        const isHome = pathname === '/' || pathname.indexOf('/home') > -1;
        const companyName = StorageUtil.getItem(LocalConst.companyName) || '';
        const companyDate = StorageUtil.getItem(LocalConst.companyData) || {};
        const overlayItem = <div className='search-log-list-box'>
            <ul className="log-box">
                {
                    (keywordList || []).map((item, index) => {
                        return (
                            <li className='log-item' key={index}>
                                <span className='txt ellipsis' role='none' title={item.keywords_}
                                      onClick={(e) => this.selectKeywordLog(e, item)}>{item.keywords_}</span>
                                <span className='ope'>
                                    <span
                                        className='tips'>{item.search_count_ > 0 ? '约' + item.search_result_ + '个商品' : '搜索历史'}</span>
                                    {
                                        this.user && this.user.id_ && !keyword &&
                                        <span className='del' role="none"
                                              onClick={(e) => this.toDeleteKeywordsById(e, item.id_)}>删除</span>
                                    }
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
            {
                this.user && this.user.id_ && !keyword &&
                <div className='keyword-foot-box'>
                    <span className='txt' role="none" onClick={this.toDeleteClientAllKeywords}>全部删除</span>
                </div>
            }
        </div>;
        const classifyList = this.handleClassifyList(
            {
                typeList: typeList,
                brandList: brandList,
                tagList: tagList,
                propertyList: propertyList
            },
            navigation_
        );
        // 头部搜索栏
        const pageHeadMain = <div className="main-wrap">
            <div className="logo-box cur" role="none" onClick={(e) => this.toLink(e, {type_: -1})}>
                <img src={logo_ || this.companyData.login_ || IMGLogo} alt=""/>
            </div>
            {
                isHome &&
                <div className="search-wrap">
                    <div className="search-box">
                        <Input type="text" allowClear className="txt" placeholder='请输入17位VIN码' maxLength={17}
                               value={vin}
                               onChange={this.changevin} onClick={this.showSearchLog} onPressEnter={this.searchVin}/>
                        <button disabled={vin.length < 7}
                                className={'search-btn'}
                                onClick={this.searchVin}><i className='iconfont icon-sousuo'/>VIN查询
                        </button>
                    </div>
                    {
                        ((isShowSearchLog && vinLogList.length > 0)) &&
                        <div className="search-dropdown-box">
                            <Scrollbars>
                                <div className="search-dropdown-wrap">
                                    {
                                        vinLogList.map((item, index) => {
                                            return (
                                                <div className="log-item" key={index} role='none'
                                                     onClick={() => this.selectLogItem(item)}>
                                                    <div
                                                        className="tit">{item.ac_brand_name_} {item.ac_type_name_} {item.vehicle_of_year_}</div>
                                                    <div className="txt">
                                                        <span>{item.vin_} {item.create_time_.split(' ')[0]}</span>
                                                        <span className="fr del" role='none'
                                                              onClick={(e) => this.delLogItem(e, item)}>删除</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Scrollbars>
                        </div>
                    }
                </div>
            }

            <div className="head-search-wrap search-wrap">
                <div className="search-box" role="none" onClick={(e) => this.toggleSearchDropdown(e)}>
                    <Input type="text"
                           className="txt"
                           allowClear
                           placeholder='请输入商品名称/规格/编号/首字母'
                           value={keyword}
                           onPressEnter={() => this.searchKeyword(keyword)}
                           onChange={this.changeKeyword}/>
                    <div className="search-btn" onClick={() => this.searchKeyword(keyword)} role="none"><i
                        className='iconfont icon-sousuo'/></div>
                    {
                        isShowSearchDropdown && keywordList.length > 0 &&
                        <div className="dropdown-box">{overlayItem}</div>
                    }
                </div>
                <Badge count={cartCount} className="cart-box"
                       onClick={() => history.push("/cart")}>
                    <i className='iconfont icon-shopping_cart_s'/>购物车
                </Badge>
            </div>

        </div>;
        // IM 未读数量
        const currentSessions = sessions.find(item => item.id === `p2p-${imCurrentFriend.account}`);
        const unread = currentSessions ? currentSessions.unread : 0;
        return (
            <Fragment>
                <div className={`page-head-wrap page-head-wrap-fix ${isFixationHead ? 'opacity-1' : ``}`}
                     style={backgroundStyle}>
                    <div className="head-main-box">
                        <div className="page-wrap">{pageHeadMain}</div>
                    </div>
                </div>
                <div className={`page-head-wrap ${isFixationHead ? 'opacity-0' : ``}`} style={backgroundStyle}>
                    {
                        banner_.show_ &&
                        is_working_ &&
                        pathname.indexOf('/home') > -1 ?
                            <div className="header-banner-box" style={headerBannerStyle}>
                                <ImgAdapt src={banner_.img_} alt=""/>
                            </div> : null
                    }
                    <div className="shop-head-user-box">
                        <div className="main-box">
                            <div className="head-welcome-txt">{`您好，${(this.ysClient.name_ || this.user.client_name_) ? `${this.ysClient.name_ || this.user.client_name_}，` : ''}欢迎来到${companyName}商城！`}</div>
                            <div className="head-user-nav-box">
                                <Popover
                                    placement="bottom"
                                    trigger="hover"
                                    content={
                                        <div className="nav-card-main">
                                            <div className="qr-code-box">
                                                <div className="code-box">
                                                    <img src={companyDate.android_url_ || IMGDownloadQrCode} alt=""/>
                                                </div>
                                                <p>Android版</p>
                                            </div>
                                            <div className="qr-code-box">
                                                <div className="code-box">
                                                    <img src={companyDate.ios_url_ || IMGDownloadQrCode} alt=""/>
                                                </div>
                                                <p>IOS版</p>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className="nav-card">
                                        <p>在手机订货</p>
                                    </div>
                                </Popover>
                                {
                                    company_wx_public_img_ &&
                                    <Popover
                                        placement="bottom"
                                        trigger="hover"
                                        content={
                                            <div className="nav-card-main">
                                                <div className="qr-code-box">
                                                    <div className="code-box"><img src={company_wx_public_img_} alt=""/>
                                                    </div>
                                                    <p>扫一扫，关注微信公众号</p>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div className="nav-card">
                                            <p>关注我们</p>
                                        </div>
                                    </Popover>
                                }

                                <div className="nav-card">
                                    <p className="user-nav-card" role="none"
                                       onClick={() => this.toPage('/collect/list')}>我的收藏</p>
                                </div>
                                <Popover
                                    placement="bottom"
                                    trigger="hover"
                                    content={
                                        <div className="user-nav-card-box">
                                            <div className="user-nav-card" role="none"
                                                 onClick={() => this.toPage('/user')}>我的账号
                                            </div>
                                            {
                                                this.isLogin && this.user && this.user.bind_phone_ &&
                                                <div className="user-nav-card" role="none"
                                                     onClick={this.exchangeAccount}>切换账号</div>
                                            }
                                            <div className="user-nav-card" role="none"
                                                 onClick={() => this.toPage('/keep/list')}>购买记录
                                            </div>

                                            <div className="user-nav-card" role="none"
                                                 onClick={CommonUtil.logout}>
                                                {this.isLogin ? '退出' : '去登录'}
                                            </div>

                                        </div>
                                    }
                                >
                                    <div className="nav-card">
                                        <p>个人中心</p>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className="head-main-box">
                        {
                            !background_.is_hide_border_ &&
                            <div className="line-wrap"/>
                        }
                        <div className="page-wrap">
                            {pageHeadMain}
                            <div className="nav-wrap">
                                {
                                    !(navigation_.classify_title_ && navigation_.classify_title_.hidden_) &&
                                    <div className="menu-wrap">
                                        {
                                            !navigation_.classify_title_ &&
                                            <div className="menu-title">
                                                全部商品分类 <i className="icon-fenlei iconfont"/>
                                            </div>
                                        }
                                        {
                                            navigation_.classify_title_ &&
                                            !navigation_.classify_title_.hidden_ &&
                                            <div className="menu-title">
                                                {navigation_.classify_title_.name_}
                                                <i className="icon-fenlei iconfont"/>
                                            </div>
                                        }
                                        <div
                                            className={
                                                classnames('menu-box', {
                                                    "dis-show": navigation_.type_tree_ && navigation_.type_tree_.show_ && isHome
                                                })
                                            }
                                            onMouseLeave={this.menuItemMouseLeave}
                                            ref='jsMenuBox'>
                                            <Scrollbars className="menu-main-box" style={classifyStyle}>
                                                {
                                                    classifyList.filter(item => {
                                                        return !item.hidden_
                                                    }).map((item, index) => {
                                                        return (
                                                            <span
                                                                role={'none'}
                                                                className="menu-item"
                                                                data-id={item.id_}
                                                                onMouseEnter={this.menuItemMouseEntre}
                                                                style={classifyFontStyle}
                                                                key={index}
                                                                onClick={() => this.selectQuery(item.type_ || 'type', item)}
                                                            >
                                                                {item.name}
                                                                {
                                                                    item.children.length > 0 &&
                                                                    <i className="goto iconfont icon-jump"
                                                                       style={classifyFontStyle}/>
                                                                }
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </Scrollbars>
                                            {
                                                classifyList.filter(item => {
                                                    return !item.hidden_ && item.children.length > 0
                                                }).map((item, index) => {
                                                    return (
                                                        <div className="menu-sub-box" id={`jsSelect_${item.id_}`}
                                                             key={index}>
                                                            {
                                                                !(navigation_.type_tree_ && navigation_.type_tree_.type_) ? (
                                                                    item.children.map((sub, subIndex) => {
                                                                        return this.renderMenuItem(sub, subIndex)
                                                                    })
                                                                ) : (
                                                                    this.renderMenuSubBoxList(item, index)
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="nav-box">
                                    <Fragment>
                                        {
                                            (navigation_.buttons_ || []).filter(item => {
                                                return !item.hidden_
                                            }).map((btn, index) => {
                                                const cls = classnames('nav-item', {
                                                    'active': btn.newLocation && (history.location.pathname === btn.newLocation.pathname)
                                                });
                                                return (
                                                    <span className={cls}
                                                          role={'none'}
                                                          onClick={() => this.navClick(btn)}
                                                          key={index}>{btn.name_}</span>
                                                )
                                            })
                                        }
                                    </Fragment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    sideAffix.length > 0 &&
                    <Affix className="affix-box">
                        <section>
                            {
                                sideAffix.map((item, index) => {
                                    return (
                                        <Popover placement="leftTop" content={this.handleAffixItem(item)}
                                                 getPopupContainer={() => this.affix} trigger="hover" key={index}>
                                            <div className="affix-item" ref={ref => this.affix = ref} role="none"
                                                 onClick={() => this.affixOnClick(item)}>
                                                <i className={`iconfont ${item.icon_}`}/>
                                            </div>
                                        </Popover>
                                    )
                                })
                            }


                            <div className="affix-item" ref={ref => this.affix = ref} role="none"
                                 onClick={() => this.affixOnClick()}>
                                <Badge size="small"
                                       count={unread}
                                >
                                    <MessageOutlined style={{color: '#9B9B9B', fontSize: 22}}/>
                                </Badge>
                            </div>

                        </section>
                    </Affix>
                }

                {
                    visibleChat && createPortal(
                        <IMChat
                            history={history}
                        />, this.bodyNode
                    )
                }
            </Fragment>
        )
    }
}

export default PageHeader
