import React, {Fragment} from 'react';
import {render,createPortal} from 'react-dom'
import QRCode from 'qrcode.react';
import UrlUtil from "./url-config";
import StorageUtil from "./storage-util";
import {LocalConst} from "./constant";
import IMGAlipay from './../assets/images/pay-alipay.png'
import IMGWeChatPay from './../assets/images/pay-wechat.png'
import IMChat from "../components/im";
import {Modal} from 'antd'

const RenderUtil = {
    renderLoginModal() {
        const node = document.createElement('section');
        document.body.appendChild(node);
        const companyId = StorageUtil.getItem(LocalConst.companyId);
        const accountRrl = UrlUtil.getAccountUrl(companyId);
        // 关闭弹窗
        const closeModal = () => {
            document.body.removeChild(node);
        };
        // 登录
        const toLogin = () => {
            window.location.href = `${accountRrl}/login`;
        };
        // 注册
        const toRegister = () => {
            window.location.href = `${accountRrl}/register`
        };
        render(<Fragment>
            <div className="login-modal">
                <div className="login-modal-title">请登录后操作</div>
                <div className="login-modal-body">
                    <button className="btn btn-confirm" onClick={toLogin}>已有账号，去登录</button>
                    <button className="btn" onClick={toRegister}>注册新账户</button>
                </div>
                <div className="close" onClick={closeModal} role="none"/>
            </div>
            <div className="mask"/>
        </Fragment>, node)
    },
    renderInquiryModal(data, props, addCallback) {
        this.node = document.createElement('section');
        document.body.appendChild(this.node);
        // 关闭弹窗
        const closeModal = () => {
            document.body.removeChild(this.node);
        };

        const toAdd = () => {
            (data.item_list_ || []).forEach(item => {
                item.from = 'add'
            });
            closeModal();
            addCallback({...data, from: 'add'});
        };
        const toDetail = () => {
            closeModal();
            props.history.push(`/inquire/detail/${data.id_}`)
        };
        render(<Fragment>
            <div className="inquiry-model">
                <div className="inquiry-model-title">
                    VIN码({data.vin_},{data.ac_brand_name_},{data.ac_type_name_})已在询价中，是否追加？
                </div>
                <div className="inquiry-model-body">
                    <button className="btn" onClick={toAdd}>追加配件</button>
                    <button className="btn" onClick={toDetail}>查看详情</button>
                </div>
                <div className="close" onClick={closeModal} role="none"/>
            </div>
            <div className="mask"/>
        </Fragment>, this.node)
    },
    renderPayModal(conf) {
        this.node = document.createElement('section');
        document.body.appendChild(this.node);
        this.node.id = 'jsPayModalBox';
        const {type, qrcodeUrl, needPay, refreshFunc} = conf;
        const companyName = StorageUtil.getItem(LocalConst.companyName);
        const payType = {wx_pub_qr: '微信', alipay_qr: '支付宝'};
        const payTypeLogo = {wx_pub_qr: IMGWeChatPay, alipay_qr: IMGAlipay};
        // 关闭弹窗
        const closeModal = () => {
            if (refreshFunc && typeof refreshFunc === 'function') {
                refreshFunc()
            }
            document.body.removeChild(this.node);
        };
        render(
            <Fragment>
                <div className="pay-modal">
                    <div className="pay-modal-title">
                        {`${payType[type]}支付`}

                        <span className="close" role="none" onClick={closeModal}><i className="iconfont icon-cancel"/></span>
                    </div>
                    <div className="pay-modal-body">
                        <div className="pay-modal-body-head">
                            <div className="left-box">
                                <p>{`正在使用${payType[type]}扫码交易`}</p>
                                <p>{`收款方：${companyName}`}</p>
                            </div>
                            <div className="logo-box">
                                <img src={payTypeLogo[type]} alt=""/>
                            </div>
                        </div>
                        <div className="pau-modal-body-main">
                            <div className="main-box">
                                <div className="title-box">扫一扫付款（元）</div>
                                <div className="price-box">{needPay}</div>
                                <div className="qrcode-box">
                                    <QRCode size={136} value={qrcodeUrl}/>
                                </div>
                                <div className="tips-box">
                                    请使用{payType[type]}扫描<br/>
                                    二维码完成支付
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="mask"/>
            </Fragment>, this.node)
    },
    renderChatModal() {
        const node = document.createElement('section');
        document.body.appendChild(node);
        const companyId = StorageUtil.getItem(LocalConst.companyId);
        const accountRrl = UrlUtil.getAccountUrl(companyId);
        // 关闭弹窗
        const closeModal = () => {
            document.body.removeChild(node);
        };
        // 登录
        const toLogin = () => {
            window.location.href = `${accountRrl}/login`;
        };
        // 注册
        const toRegister = () => {
            window.location.href = `${accountRrl}/register`
        };
        render(<Modal visible={true}><IMChat/></Modal>, node)
    },
};


export default RenderUtil;

