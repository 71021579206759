function onOfflineSysMsgs(sysMsgs, data) {
    console.log('收到离线系统通知', sysMsgs);
    pushSysMsgs(sysMsgs, data);
}

function onSysMsg(sysMsg, data) {
    console.log('收到系统通知', sysMsg, data)
    pushSysMsgs(sysMsg, data);
}

function onUpdateSysMsg(sysMsg, data) {
    pushSysMsgs(sysMsg, data);
}

function pushSysMsgs(sysMsgs, data) {
    data.sysMsgs = window.nim.mergeSysMsgs(data.sysMsgs, sysMsgs);
    refreshSysMsgsUI();
}

function onSysMsgUnread(obj, data) {
    console.log('收到系统通知未读数', obj);
    data.sysMsgUnread = obj;
    refreshSysMsgsUI();
}

function onUpdateSysMsgUnread(obj, data) {
    console.log('系统通知未读数更新了', obj);
    data.sysMsgUnread = obj;
    refreshSysMsgsUI();
}

function refreshSysMsgsUI() {
    // 刷新界面
}

function onOfflineCustomSysMsgs(sysMsgs) {
    console.log('收到离线自定义系统通知', sysMsgs);
}

function onCustomSysMsg(sysMsg) {
    console.log('收到自定义系统通知', sysMsg);
}

export {
    onOfflineSysMsgs,
    onSysMsg,
    onUpdateSysMsg,
    onSysMsgUnread,
    onUpdateSysMsgUnread,
    onOfflineCustomSysMsgs,
    onCustomSysMsg
}
