import React, {Fragment, useState, useEffect, useCallback} from 'react'
import './../styles.scss'
import {LocalConst} from "../../../utils/constant";
import {CloseOutlined} from '@ant-design/icons';
import ChatItem from "./../components/chat-item";
import InfiniteScroll from 'react-infinite-scroller';
import {LoadingOutlined} from '@ant-design/icons'

const uuid = () => parseInt(Math.random() * 100000000) + (new Date()).getTime();
const HistoryChat = (props) => {
    const {
        dispatch, imCurrentFriend,
        myInfo, audioPlayIndex, toggleHistory,
        audioPress, downloadFile, toCustomMsgDetail
    } = props;
    const {account, nick, avatar} = imCurrentFriend;
    const [messageList, setMessageList] = useState([]);
    const [hasLoad, setHasLoad] = useState(true);

    useEffect(() => {
        callback();
    }, [dispatch]);


    const callback = useCallback(() => {
        getMsgList();
    }, []);


    const getMsgList = (lastMsgId) => {
        // 获取历史信息，通过云库
        window.nim.getHistoryMsgs({
            scene: 'p2p',
            to: account,
            endTime: lastMsgId,
            done: (error, obj) => {
                if (!error) {
                    let newList = [...messageList, ...obj.msgs];
                    newList = newList.sort((a, b) => b.time - a.time);
                    setMessageList(newList);
                    if(obj.msgs.length <100){
                        setHasLoad(false)
                    }
                }
            }
        });
    };
    const onRefresh = () => {
        getMsgList(messageList[messageList.length - 1]?.time);

    };
    // 关闭 im
    const close = () => {
        toggleHistory(false)
    };

    return (
        <Fragment>
            <div className={'im-chat-modal-box'}>
                <div className="im-chat-modal-header-box">
                    <div className="user-info-box">
                        <div className="user-avatar-box">
                            <img src={avatar || LocalConst.AvatarDefault} alt=""/>
                        </div>
                        <div className="user-name-box">
                            {nick}
                        </div>
                    </div>
                    <div className="im-chat-operation-box" role={'none'} onClick={close}>
                        <span className={'item'}><CloseOutlined/></span>
                    </div>
                </div>
                <div className="im-chat-modal-body-box">
                    <InfiniteScroll
                        loadMore={onRefresh}
                        hasMore={hasLoad}
                        loader={
                            <div className="history-loader" key={'history-loader'}>
                                <LoadingOutlined/> &nbsp;
                                数据加载中...
                            </div>
                        }
                        useWindow={false}
                    >
                        {
                            (messageList || []).map((item, index) => {
                                return (
                                    <ChatItem
                                        key={index}
                                        data={{...item, index: index}}
                                        avatar={item.flow === 'in' ? avatar : myInfo && myInfo.avatar}
                                        onPress={audioPress}
                                        audioPlayIndex={audioPlayIndex}
                                        toCustomMsgDetail={toCustomMsgDetail}
                                        downloadFile={downloadFile}
                                    />
                                )


                            })
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </Fragment>
    )
};

export default HistoryChat





