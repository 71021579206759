import React from "react";
import {Base64} from 'js-base64';

import {LocalConst} from "./constant";
import StorageUtil from "./storage-util";
import UrlUtil from "./url-config";
import CheckUtil from "./check-util";
import {SystemFetch} from "../services/service";
import RenderUtil from "./render-util";


const CommonUtil = {
        //设置商城主题
        setTheme: (theme) => {
            if (!theme) {
                theme = `ffb919`
            }
            document.body.className = `page-reset theme-${theme}`;
            const $jsFullLoading = document.getElementById("jsFullLoading");
            if ($jsFullLoading) {
                document.body.removeChild($jsFullLoading);
            }
        },
        //设置自定义样式表
        setCssFile: (url) => {
            //设置自定义样式
            if (url) {
                let body = document.getElementsByTagName("body")[0];
                let deleteN = document.getElementById('jsXStyle');
                if (deleteN) {
                    body.removeChild(deleteN);
                }
                let linkDom = document.createElement("link");
                linkDom.id = 'jsXStyle';
                linkDom.rel = "stylesheet";
                linkDom.type = "text/css";
                linkDom.href = url;
                body.appendChild(linkDom);
            }

        },
        //设置第三方统计代码
        setStatisticalCode(data) {
            setTimeout(() => {
                let body = document.getElementsByTagName("body")[0];
                if (data) {
                    data = JSON.parse(data);
                    data.forEach(item => {
                        let scriptDom = document.createElement("script");
                        scriptDom.type = "text/javascript";
                        scriptDom.src = item.name;
                        body.appendChild(scriptDom);
                    })
                } else {
                    let scriptDom = document.createElement("script");
                    scriptDom.type = "text/javascript";
                    scriptDom.src = 'https://hm.baidu.com/hm.js?5c6da7c14905c6a9a405904833d3dd8d';
                    body.appendChild(scriptDom);
                }
            }, 300)

        },
        //自定义浏览器icon
        setIco: (img) => {
            //修改浏览器标签的icon  数据来源于公司详情的PC管理端小logo
            if (img) {
                let head = document.getElementsByTagName("head")[0];
                let deleteN = document.getElementById('jsXIcon');
                if (deleteN) {
                    head.removeChild(deleteN);
                }
                let linkDom = document.createElement("link");
                linkDom.rel = "icon";
                linkDom.type = "image/x-icon";
                linkDom.href = img;
                head.appendChild(linkDom);
            }

        },
        //设置背景样式
        setBackground: (background) => {

            let body = document.getElementsByTagName("body")[0];
            let deleteN = document.getElementById('jsPageStyle');
            if (deleteN) {
                body.removeChild(deleteN);
            }
            let linkDom = document.createElement("style");
            linkDom.id = 'jsPageStyle';
            linkDom.rel = "stylesheet";
            linkDom.innerText = 'body{background:' + background.color_ + ' ' + (background.img_ ? 'url(' + background.img_ + ')' : '') + ' ' + (background.repeat_ === 'padding' ? 'no-repeat' : background.repeat_) + ' ' + background.vertical_ + ' ' + background.horizontal_ + (background.repeat_ === 'padding' ? ' / 100% 100%' : '') + '}';
            body.appendChild(linkDom);

        },

        clearAccount: () => {
            //订货端退出
            StorageUtil.delItem(LocalConst.mShop_user);
            StorageUtil.delItem(LocalConst.ysClient);
            StorageUtil.delItem(LocalConst.token);
            StorageUtil.delItem(LocalConst.accountList);
            StorageUtil.delItem(LocalConst.cartCount);
            StorageUtil.delItem(LocalConst.localHistory, true);
            StorageUtil.delItem(LocalConst.prevLocation, true);
        },
        logout: () => {
            CommonUtil.clearAccount();
            // 获取分享人
            const companyId = StorageUtil.getItem(LocalConst.companyId);
            const shareUserId = StorageUtil.getItem(LocalConst.shareUserId, true);
            const shareClientId = StorageUtil.getItem(LocalConst.shareClientId, true);

            const hostUrl = UrlUtil.getAccountUrl(companyId);
            const search = shareUserId ? `&u=${shareUserId}` : shareClientId ? `&c=${shareClientId}` : ``;
            window.location.href = `${hostUrl}/login?companyId=${companyId}${search}`;

        },
        //企業注冊
        toCompanyRegister() {
            let url = UrlUtil.getAccountUrl() + '/register';
            window.location.href = url;
        },

        // 前往订货端体验账号
        toShopExperience() {
            return new Promise((resolve, reject) => {
                const companyId = StorageUtil.getItem(LocalConst.companyId);
                SystemFetch.shopExperience({
                    company_: companyId
                }, rs => {
                    if (rs.status === 1 && rs.data.type_ === 3) {
                        // app缓存用户信息
                        const auth_code_ = Base64.encode(JSON.stringify({
                            bind_phone_: rs.data.bind_phone_,
                            company_id_: rs.data.company_id_,
                            company_name_: rs.data.company_name_,
                            company_theme_: rs.data.company_theme_,
                            client_id_: rs.data.client_id_,
                            env_: rs.data.env_,
                            login_: rs.data.login_,
                            id_: rs.data.id_,
                            name_: rs.data.name_,
                            phone_: rs.data.phone_,
                            type_: rs.data.type_,
                            token_: rs.data.token_,
                            head_img_: rs.data.head_img_,
                            auth_code_: rs.data.auth_code_,
                            duty_name_: rs.data.duty_name_,
                            dept_name_: rs.data.dept_name_,
                        }));
                        // 更新数据中心数据并跳转
                        window.location.href = `${UrlUtil.getPcShopUrl()}/home?companyId=${companyId}&auth_code_=${auth_code_}`;

                        resolve(rs);
                    } else {
                        reject(rs);
                        // Toast.info('数据错误，请联系客服');
                    }
                })
            })
        },
        //跳转管理端体验
        toMgrExperience() {
            const companyId = StorageUtil.getItem(LocalConst.companyId);
            SystemFetch.experience({company_: companyId}, rs => {
                if (rs.status === 1 && rs.data.type_ !== 3) {
                    const auth_code_ = Base64.encode(JSON.stringify({
                        bind_phone_: rs.data.bind_phone_,
                        company_id_: rs.data.company_id_,
                        company_name_: rs.data.company_name_,
                        company_theme_: rs.data.company_theme_,
                        client_id_: rs.data.client_id_,
                        env_: rs.data.env_,
                        login_: rs.data.login_,
                        id_: rs.data.id_,
                        name_: rs.data.name_,
                        phone_: rs.data.phone_,
                        type_: rs.data.type_,
                        token_: rs.data.token_,
                        head_img_: rs.data.head_img_,
                        auth_code_: rs.data.auth_code_,
                        duty_name_: rs.data.duty_name_,
                        dept_name_: rs.data.dept_name_,
                    }));
                    // 更新数据中心数据并跳转
                    window.location.href = `${UrlUtil.getPcMgrUrl()}/home?companyId=${companyId}&auth_code_=${auth_code_}`

                } else {
                    // Toast.message('数据错误，请联系客服');
                }
            })

        },
        // 获取url参数
        getSearchFromUrl(search = window.location.search) {
            let searchObj = {};
            const subIndex = search.indexOf("?");
            if (subIndex > -1) {
                search = search.substr(subIndex + 1);
            }
            search.split('&').forEach(item => {
                const itemArr = item.split("=");
                if (itemArr[0] && itemArr[1]) {
                    searchObj[itemArr[0]] = itemArr[1];
                }
            });
            return searchObj;
        },
        // 设置价格精度
        priceScale(price, scale = 2) {
            if (isNaN(Number(price))) return;
            // 填补用于修复 js原生银行家算法
            var padNum = price > 0 ? 0.5 : -0.5;
            var times = Math.pow(10, scale);
            var s1 = price.toString();
            var m = 0;
            try {
                m += s1.split(".")[1].length;
            } catch (e) {

            }
            var priceZoom = (s1 * Math.pow(10, m)) * times / Math.pow(10, m);
            var des = parseInt(priceZoom + padNum, 10) / times;

            return des.toFixed(scale);
        },

        // 设置商品数量精度
        countScale(count, scale) {
            const accuracy = scale || StorageUtil.getItem(LocalConst.countScale) || 0;
            return Number(count).toFixed(accuracy);
        },

        // 替换表情
        replaceEmoji(v) {
            if (!v.trim()) return v;
            return v.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83C[\uDC00-\uDFFF]/g, "")

        },
        //时间格式化
        formatDate(date) {
            const pad = n => n < 10 ? `0${n}` : n;
            const dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
            const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
            return `${dateStr} ${timeStr}`;
        },
        // 获取装修跳转路径
        getDecorateLocation(target, unCheckLogin) {
            if (typeof target === "undefined") return;
            const newLocation = {
                isAllowJump: true,
                query: {
                    companyId: StorageUtil.getItem(LocalConst.companyId)
                }
            };
            const checkLogin = (newLocation) => {
                const isLogin = CommonUtil.isLogin();
                if (!isLogin && !unCheckLogin) {
                    newLocation.isAllowJump = false;
                    RenderUtil.renderLoginModal();
                }
            };

            switch (target.type_) {
                case -1://首页
                    newLocation.pathname = "/home";
                    break;
                case -2://商品
                    newLocation.pathname = "/goods/list";
                    break;
                case -3://订单
                    checkLogin(newLocation);
                    newLocation.pathname = "/order/list";
                    break;
                case -4://款项
                    checkLogin(newLocation);
                    newLocation.pathname = "/deposit/detail";
                    break;
                case -5:// 消息 固定的导航
                case 18:// 通知公告列表
                    checkLogin(newLocation);
                    newLocation.pathname = "/notices/list";
                    break;
                case 1: //已上架商品
                    if (!target.val_) return;
                    newLocation.pathname = `/goods/detail/${target.val_}`;
                    break;
                case 2: //商品分类
                    if (!target.val_) return;
                    newLocation.pathname = "/goods/list";
                    newLocation.query.type_ = target.val_;
                    break;
                case 3: //商品标签
                    if (!target.val_) return;
                    newLocation.pathname = "/goods/list";
                    newLocation.query.tag_ = target.val_;
                    break;
                case 4: //商品品牌
                    if (!target.val_) return;
                    newLocation.pathname = "/goods/list";
                    newLocation.query.brand_ = target.val_;
                    break;
                case 5: //通知公告
                    if (!target.val_) return;
                    checkLogin(newLocation);
                    newLocation.pathname = `/notices/detail/${target.val_}`;
                    break;
                case 6: //商品促销
                    if (!target.val_) return;
                    checkLogin(newLocation);
                    newLocation.pathname = "/goods/list";
                    newLocation.query.goods_activity_id_ = target.val_;
                    break;
                case 7: //模板页面
                    if (!target.val_) return;
                    newLocation.pathname = `/page/${target.val_}`;
                    break;
                case 8: //外部链接
                    if (!CheckUtil.isUrl(target.val_)) return;
                    newLocation.pathname = `${target.val_}`;
                    break;
                case 9: // 全部商品
                    newLocation.pathname = "/goods/list";
                    break;
                case 10: // 购物车
                    checkLogin(newLocation);
                    newLocation.pathname = "/cart";
                    break;
                case 11: // 收藏商品
                    checkLogin(newLocation);
                    newLocation.pathname = "/collect/list";
                    break;
                case 23: // 商品属性
                    newLocation.pathname = "/goods/list";
                    newLocation.query.property_id_ = target.val_;
                    break;
                case 24: // 预售商品
                    newLocation.pathname = "/goods/list";
                    newLocation.query.quick_search_ = 6;
                    break;
                case 96: // 询价单
                    checkLogin(newLocation);
                    newLocation.pathname = "/inquire/list";
                    break;
                case 97: // 立即询价
                    checkLogin(newLocation);
                    newLocation.pathname = "/inquire";
                    break;
                case 98: // 按VIN码找
                    newLocation.pathname = "/search/vin";
                    break;
                case 100: // 按车型找
                    newLocation.pathname = "/search/car-brand";
                    break;
                // no default
            }
            return newLocation;
        },
        // 保存登录信息
        saveStorageFormLogin(loginUser) {
            StorageUtil.setItem(LocalConst.mShop_user, loginUser);
            StorageUtil.setItem(LocalConst.token, loginUser.token_);
            StorageUtil.setItem(LocalConst.companyId, loginUser.company_id_);
            StorageUtil.setItem(LocalConst.companyName, loginUser.company_name_);
            StorageUtil.setItem(LocalConst.companyTheme, loginUser.company_theme_);
            StorageUtil.setItem(LocalConst.authCode, loginUser.auth_code_);
            // YSDH-7542
            StorageUtil.setItem(LocalConst.isShowBindTips, 1);
            StorageUtil.setItem(LocalConst.isLighting, loginUser.isLighting);

            // 3_1_9:是否微信绑定
            StorageUtil.setItem(LocalConst.wechatBind, loginUser.wechat_bind_);
            StorageUtil.setItem(LocalConst.subsidiaryId, loginUser.subsidiary_id_);

            StorageUtil.setItem(LocalConst.isClientChildAccount, loginUser.is_client_child_account_); // 是否是员工
            StorageUtil.setItem(LocalConst.clientAccountType, loginUser.client_account_type_); // 员工类型

            let isOrdinaryEmployees = 0;
            if (loginUser.is_client_child_account_ && loginUser.client_account_type_ === 2) {
                isOrdinaryEmployees = 1
            }
            StorageUtil.setItem(LocalConst.isOrdinaryEmployees, isOrdinaryEmployees); // 是否是普通员工账号
        },
        checkLoginStatus(loginStatus) {
            if (typeof loginStatus !== 'number') return null;
            // let companyDate = StorageUtil.getItem(LocalConst.companyData);
            // const link_phone_ = (companyDate && companyDate.link_phone_) || '0755-2665 9381';
            /*           const company_name_ = companyDate && companyDate.name_;
                       const actions = [{text: '我知道了'}, {
                           text: '呼叫', onPress: () => {
                               return new Promise(() => {
                                   window.location.href = `tel:${link_phone_}`
                               });

                           }
                       }];
                       const telNode = <a href={`tel:${link_phone_}`}>（{link_phone_}）</a>;*/
            switch (loginStatus) {
                case 0:
                    break;
                case 1:
                    return true;
                case 2:
                    // Modal.alert(null, <div>登录账号不存在<br/>请联系管理员开通<br/>{telNode}</div>, actions);
                    break;
                case 3:
                    //账号禁用
                    // Modal.alert(null, <div>该企业账号被禁用<br/>请联系云上订货<br/>{telNode}</div>, actions);
                    break;
                case 4:
                    //试用结束
                    // Modal.alert(null, <div>该企业账号试用期结束<br/>请联系云上订货<br/>{telNode}</div>, actions);
                    break;
                case 5:
                    //使用过期
                    // Modal.alert(null, <div>该企业账号已过期了<br/>需要续费才能使用<br/>请联系云上订货 <br/>{telNode}</div>, actions);
                    break;
                case 6:
                    //使用过期
                    // Modal.alert(null, <div>该企业不存在此帐号</div>, [{text: '我知道了'}]);
                    break;
                case 7:
                    // 客户账号已禁用
                    // Modal.alert(null, <div>您的订货账号已被企业{company_name_}禁用，不可登录。请联系管理员{telNode}</div>, actions);
                    break;
                case 8:
                    // 客户账号已停用
                    // Modal.alert(null, <div>您已不是企业{company_name_}的合作客户，不可登录。请联系管理员{telNode}</div>, actions);
                    break;
                case 9:
                    // // 店铺账号已禁用
                    // Modal.alert(null, <div>您的店铺已被停用，不可登录。请联系管理员{telNode}
                    // </div>, actions);
                    break;
                case 10:
                    // tv未开通装修专版
                    // Modal.alert(null, <div>该账号未开通灯饰专版功能。请联系管理员{telNode}</div>, actions);
                    break;
                default:
                // Toast.info(`数据异常请重新尝试或者联系管理员`, 1, null, false);
            }

        },

        //数据格式转换
        stringToArr(string) {
            if (Array.isArray(string)) return string;
            try {
                return JSON.parse(string) || []
            } catch (e) {
                return []
            }
        },
        stringToObj(string) {
            if (!string) return;
            if (CommonUtil.isType(string) === 'Object') return string;

            try {
                return JSON.parse(string)
            } catch (e) {
                console.log('stringToObj', e);
                return {}
            }
        },
        isType(value) {
            const type = Object.prototype.toString.call(value);
            return type.replace(/\[object (\w+)\]/, '$1')
        },
        isLogin() {
            const companyId = StorageUtil.getItem(LocalConst.companyId);
            const ysUser = StorageUtil.getItem(LocalConst.mShop_user);
            if (companyId && ysUser && ysUser.company_id_ === companyId) {
                return true;
            }

        },
        timeTranslate(timer) {
            if ((!timer && timer !== 0) || timer < 0) return {};
            //计算出相差天数
            let days = Math.floor(timer / (24 * 3600 * 1000));
            //计算出相差小时数
            let leave1 = timer % (24 * 3600 * 1000); //计算天数后剩余毫秒数
            let hours = Math.floor(leave1 / (3600 * 1000));

            //计算出相差分钟数
            let leave2 = leave1 % (3600 * 1000);
            let minutes = Math.floor(leave2 / (60 * 1000));

            //计算出相差秒数
            let leave3 = leave2 % (60 * 1000);
            let seconds = Math.round(leave3 / 1000);
            //计算出剩余毫秒数
            let leave4 = leave3 % (1000);
            let msec = Math.round(leave4).toString().charAt(0);

            //字符串
            let time = '';
            let countdownCountTxt = ''
            if (days > 0) {
                time = `剩 <i>${days}</i> 天`;
                countdownCountTxt = `${days}天${hours}小时${minutes}分${seconds}秒`
            } else if (hours > 0) {
                time = `剩 <i>${hours}</i> 小时`;
                countdownCountTxt = `${hours}小时${minutes}分${seconds}秒`
            } else {
                time = `<i>${minutes}</i> 分 <i>${seconds}</i> 秒 <i>${msec}</i>`;
                countdownCountTxt = `${minutes}分${seconds}秒`
            }
            const fill = v => (v >= 10 || v < 0) ? v : `0${v}`;
            return {
                d: days, //天
                h: fill(hours),//小时
                m: fill(minutes),//分钟
                s: fill(seconds),//秒
                ms: msec,//豪秒
                time: time,
                countdownCountTxt: countdownCountTxt
            }
        },
        datetimeFormat(time) {
            let seconds = time / 1000;
            switch (true) {
                case seconds <= 60:
                    return "刚刚";
                case seconds > 60 && seconds <= 3600:
                    return Math.floor(seconds / 60) + "分钟前";
                case seconds > 3600 && seconds <= 3600 * 24:
                    return Math.floor(seconds / 3600) + "小时前";
                case seconds > 3600 * 24 && seconds <= 3600 * 24 * 30:
                    return Math.floor(seconds / (3600 * 24)) + "天前";
                case seconds > 3600 * 24 * 30 && seconds <= 3600 * 24 * 30 * 12:
                    return Math.floor(seconds / (3600 * 24 * 30)) + "个月前";
                case seconds > 3600 * 24 * 30 * 12 :
                    return Math.floor(seconds / (3600 * 24 * 30 * 12)) + "年前";
                default:
                    return "未知类型";
            }
        },
        priceUnit(price) {
            if (!price && price !== 0) return;
            if (price > 100000000) {
                return (price / 100000000).toFixed(2) + '亿'
            } else if (price > 10000000) {
                return (price / 10000000).toFixed(2) + '千万'
            } else if (price > 1000000) {
                return (price / 1000000).toFixed(2) + '百万'
            } else if (price > 10000) {
                return (price / 10000).toFixed(2) + '万'
            } else {
                return price.toFixed(2)
            }
        },
        formatIosTime(t) {
            return t.replace(/-/g, "/");
        },
        // 十六进制颜色转RGB
        colorRgb(color, alpha) {
            let sColor = color.toLowerCase();
            // 十六进制颜色值的正则表达式
            let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
            // 如果是16进制颜色
            if (sColor && reg.test(sColor)) {
                if (sColor.length === 4) {
                    let sColorNew = "#";
                    for (let i = 1; i < 4; i += 1) {
                        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
                    }
                    sColor = sColorNew;
                }
                // 处理六位的颜色值
                let sColorChange = [];
                for (let i = 1; i < 7; i += 2) {
                    sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
                }
                if (typeof alpha === 'number') {
                    sColorChange.push(alpha / 100);
                    return "RGBA(" + sColorChange.join(",") + ")";
                }
                return "RGB(" + sColorChange.join(",") + ")";
            }
            return sColor;
        },
        getBackgroundColor(theme, alpha, gradual) {
            const rgbaAlpha = CommonUtil.colorRgb('#' + theme, (alpha || '60'));
            const rgba = CommonUtil.colorRgb('#' + theme, '100');
            const backgroundStyle = {
                background: 'linear-gradient(180deg,' + rgbaAlpha + ' 0%,' + rgba + ((gradual || 68) + '%,') + rgba + ' 100%)',
            };
            return backgroundStyle
        },
        checkExperience: companyId => {
            const experienceCompanyIds = [12];
            return experienceCompanyIds.includes(Number(companyId));
        },
        // 获取配送时间
        getDistributionTime: (second) => {
            if (typeof second !== 'number') return null;
            let h = Math.ceil(second / 60 / 60);
            return <span>预计<span className="time">{h}小时</span>送达</span>
        },
        // 随机字符
        uuid: (len, radix) => {
            const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            let chars = CHARS, uuid = [], i;
            radix = radix || chars.length;

            if (len) {
                // Compact form
                for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                // rfc4122, version 4 form
                let r;

                // rfc4122 requires these characters
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';

                // Fill in random data.  At i==19 set the high bits of clock sequence as
                // per rfc4122, sec. 4.1.5
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }

            return uuid.join('');
        },
        // 获取库存
        getStockText: (stock, stockType = 1, bookArrivalDays, scale = 1) => {
            if (+stockType === 2) return `预定${bookArrivalDays}天`;
            const type = StorageUtil.getItem(LocalConst.stockShowType);
            const showStock = parseInt(stock / scale);
            switch (type) {
                case 0:
                    return null;
                case 1:
                    return showStock > 0 ? `有货` : '无货';
                case 2:
                    return showStock > 0 ? `库存：${showStock}` : '缺货';

                default:
                    return null
            }
        },
        encode(_map, _content) {
            _content = '' + _content
            if (!_map || !_content) {
                return _content || ''
            }
            return _content.replace(_map.r, function ($1) {
                var _result = _map[!_map.i ? $1.toLowerCase() : $1]
                return _result != null ? _result : $1
            });
        },
        escape(_content) {
            let _reg = /<br\/?>$/
            let _map = {
                r: /\<|\>|\&|\r|\n|\s|\'|\"/g,
                '<': '&lt;',
                '>': '&gt;',
                '&': '&amp;',
                ' ': '&nbsp;',
                '"': '&quot;',
                "'": '&#39;',
                '\n': '<br/>',
                '\r': ''
            }
            _content = CommonUtil.encode(_map, _content)
            return _content.replace(_reg, '<br/>');
        },
        imAddFriend: ({dispatch, oldFriends = [], account}) => {
            window.nim.addFriend({
                account: account,
                done: (error, obj) => {
                    console.log('直接加为好友' + (!error ? '成功' : '失败'), error, obj);
                    if (!error) {
                        window.nim.getUser({
                            account: obj.account,
                            done: (err, friend) => {
                                console.log('获取用户资料' + (!err ? '成功' : '失败'), err, friend);
                                if (!err) {
                                    const newFriends = window.nim.mergeFriends(oldFriends, friend);
                                    dispatch({
                                        type: 'appModel/saveState',
                                        payload: {
                                            users: newFriends
                                        }
                                    })
                                }
                            }
                        });


                    }
                }
            });
        }
    }
;

export default CommonUtil;

