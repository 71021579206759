import fetch from 'dva/fetch';
import {Modal} from 'antd';
import StorageUtil from './storage-util';
import CommonUtil from "@utils/common-util";

function parseJSON(response) {
    return response.json();
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error["status-code"] = response.headers.get("status-code");
    error.status = response.status;
    error.response = response;
    throw error;
}

function getSearchFromObject(param) {
    if (param == null) return '';
    if (typeof param !== 'object') return '';
    let _search = '?';
    for (const key in param) {
        _search += `${key}=${encodeURIComponent(param[key])}&`;
    }
    return _search.slice(0, -1);
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(meth, url, params, success) {
    let token = StorageUtil.getItem("pcShop-token");
    /**
     * meth === 'body' 兼容实体传参
     * */
    const opts = {
        method: meth.toLowerCase() === 'body' ? 'POST' : meth,
        mode: 'cors',
        headers: {
            'Content-Type': (meth.toLowerCase() === 'get' || meth.toLowerCase() === 'body') ? 'application/json;charset=utf-8' : 'application/x-www-form-urlencoded',
            'yunshl_token': token,
            'token_type': 'PC',
            'app_source': 'PC',
        },
        body: (meth.toLowerCase() === 'get') ? null : (meth.toLowerCase() === 'body' ? JSON.stringify(params) : getSearchFromObject(params).substring(1)),
    };
    if (meth.toLowerCase() === 'get') {
        url += getSearchFromObject(params);
    }

    return fetch(url, opts)
        .then(checkStatus)
        .then(parseJSON)
        .then(data => {
            success && success(data);
            return data;
        })
        .catch(err => {
            if (err.status === 401) {
                let titleTips = '登录过期';
                // @ts-ignore
                let contentTips = '登录信息已经过期，请重新登录';
                // status-code 401.3:下线通知 401.4:登录过期
                if (err['status-code'] === '401.3') {
                    titleTips = '下线通知';
                    // @ts-ignore
                    contentTips = '您的账号已在其他设备登录，请重新登录。 如非本人操作，请及时修改密码';
                }
                CommonUtil.clearAccount();
                /*处理多次执行*/
                Modal.destroyAll();
                Modal.error({
                    centered: true,
                    title: titleTips,
                    content: contentTips,
                    okText: '登录',
                    onOk: () => {
                        CommonUtil.logout();
                    }
                })

            }
            if (err.status === 409) {
                // 处理多次执行
                Modal.destroyAll();
                Modal.error({
                    title: '当前单据已被修改过，请重新刷新本页后再操作',
                    okText: '刷新',
                    onOk: () => {
                        window.location.reload();
                    }
                });
            }
            if (err.response) {
                err = err.response;
                err.message = err.statusText;
            }

            return err;
        });
}


