import React, {Fragment} from 'react';
import classnames from 'classnames'
import './style.scss'
import StorageUtil from "../../utils/storage-util";
import {LocalConst} from "../../utils/constant";

class UserMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    toPage = (item) => {
        const {history} = this.props;
        history.push(item.router)
    };

    render() {
        const {pathname} = this.props;
        const menuList = [
            {
                name: '我的',
                children: [
                    {
                        name: '个人中心',
                        router: '/user'
                    },
                    {
                        name: '通知公告',
                        router: '/notices/list'
                    },
                    {
                        name: '订单信息',
                        router: '/order/message'
                    },
                    {
                        name: '收藏商品',
                        router: '/collect/list'
                    },
                    {
                        name: '购买记录',
                        router: '/keep/list'
                    },
                    {
                        name: '优惠券',
                        router: '/coupon/list'
                    }
                ]
            },
            {
                name: '询价管理',
                children: [{
                    name: '询价单',
                    router: '/inquire/list'
                },
                // {
                //     name: '求购单',
                //     router: '/buying/list'
                // },
                ]
            },
            {
                name: '订单管理',
                children: [{
                    name: '订货单',
                    router: '/order/list'
                }, {
                    name: '退货单',
                    router: '/refund/list'
                },]
            },
            {
                name: '财务管理',
                children: [{
                    name: '对账明细',
                    router: '/deposit/detail'
                }, {
                    name: '授信管理',
                    router: '/credit/quota',
                    hidden: StorageUtil.getItem(LocalConst.applicationStatus).application_101_status_ === 0
                },]
            },
            {
                name: '系统设置',
                children: [{
                    name: '帐号信息',
                    router: '/account'
                }, {
                    name: '收货地址',
                    router: '/address'
                }, {
                    name: '员工管理',
                    router: '/staff/list'
                },]
            },
        ];

        return (
            <section className="user-menu-box">
                <dl>
                    {
                        menuList.filter(item => !item.hidden).map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <dt>{item.name}</dt>
                                    {
                                        item.children.filter(subItem => !subItem.hidden).map((subItem, sIndex) => {
                                            const cls = classnames({'active': subItem.router === pathname});
                                            return (
                                                <dd className={cls} role="none" key={sIndex}
                                                    onClick={() => this.toPage(subItem)}>{subItem.name}</dd>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }
                </dl>
            </section>
        )
    }
}

export default UserMenu
