function sendMsgDone(error, msg, dispatch) {
    if (error) {
        console.log(error.message);
        return
    }
    dispatch({
        type: 'appModel/saveState',
        payload: {
            newSessions: msg
        }
    })

}

const SendMessage = (options) => {
    const {
        scene = 'p2p', //消息场景
        value, // 消息内容
        type, // 消息类型
        recipient, // 接收人
        dispatch,
        content, // 自定义消息内容
        file,
        msgs,//历史消息
    } = options;
    switch (type) {
        case 'text':
        default:
            window.nim.sendText({
                scene: scene,
                to: recipient,
                text: value,
                done: (error, msg) => sendMsgDone(error, msg, dispatch)
            });
            break;
        case "custom":
            window.nim.sendCustomMsg({
                scene: scene,
                to: recipient,
                content: content,
                done: (error, msg) => sendMsgDone(error, msg, dispatch)
            });
            break;
        case "file":
        case "image":
            window.nim.sendFile({
                scene: scene,
                to: recipient,
                file: file,
                type: type,
                done: (error, msg) => sendMsgDone(error, msg, dispatch)
            });
            break;
    }
};

export default SendMessage
