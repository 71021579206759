
// 好友列表
function onFriends(friends,data) {
    console.log('收到好友列表', friends);
    data.friends = window.nim.mergeFriends(data.friends, friends);
    data.friends = window.nim.cutFriends(data.friends, friends.invalid);
    refreshFriendsUI();
}

// 同步好友列表
function onSyncFriendAction(obj) {
    console.log('收到好友操作', obj);
    switch (obj.type) {
        case 'addFriend':
            console.log('你在其它端直接加了一个好友' + obj);
            onAddFriend(obj.friend);
            break;
        case 'applyFriend':
            console.log('你在其它端申请加了一个好友' + obj);
            break;
        case 'passFriendApply':
            console.log('你在其它端通过了一个好友申请' + obj);
            onAddFriend(obj.friend);
            break;
        case 'rejectFriendApply':
            console.log('你在其它端拒绝了一个好友申请' + obj);
            break;
        case 'deleteFriend':
            console.log('你在其它端删了一个好友' + obj);
            onDeleteFriend(obj.account);
            break;
        case 'updateFriend':
            console.log('你在其它端更新了一个好友', obj);
            onUpdateFriend(obj.friend);
            break;
        // no default
    }
}

// 加入好友列表
function onAddFriend(friend,data) {
    data.friends = window.nim.mergeFriends(data.friends, friend);
    refreshFriendsUI();
}

// 删除好友
function onDeleteFriend(account,data) {
    data.friends = window.nim.cutFriendsByAccounts(data.friends, account);
    refreshFriendsUI();
}

// 更新好友
function onUpdateFriend(friend,data) {
    data.friends = window.nim.mergeFriends(data.friends, friend);
    refreshFriendsUI();
}

// 刷新好友列表
function refreshFriendsUI() {
    // 刷新界面
}

export {
    onFriends,
    onSyncFriendAction
}
