import React,{Fragment} from 'react'
const CustomMsgRender = ({customMsg, sendFunc, hiddenBtn, toDetail}) => {
    if (!customMsg) return null;
    const {type} = customMsg;
    const send = (e,type) => {
        e && e.stopPropagation();
        e && e.nativeEvent.stopImmediatePropagation();
        const content = {"type": type, "data": customMsg};
        const options = {
            scene: 'p2p',
            content: JSON.stringify(content),
            type: 'custom',
        };
        sendFunc(options)
    };
    switch (type) {
        case 'inquiry':
            return (
                <div className={'custom-msg-wrap-box'} role={'none'} onClick={toDetail}>
                    <div className={'img-box'}><img src={customMsg.ac_brand_logo_img_} alt=""/></div>
                    <div className={'info-box'}>
                        <div className={'title-box'}>
                            {customMsg.ac_brand_name_}&nbsp;
                            {customMsg.ac_version_name_}&nbsp;
                            {customMsg.vehicle_of_year_}&nbsp;
                            {customMsg.ac_displacement_name_}&nbsp;
                            {customMsg.engine_code_}
                        </div>
                        <div className={'tips-box'}>{customMsg.vin_},{customMsg.code_}</div>
                        <div className={'label-box'}>
                            <span className='label-tag'>询</span>
                            {customMsg.inquiry_sheet_item_name_}
                        </div>
                        {
                            !hiddenBtn &&
                            <div className={'btn-box'}>
                                <button onClick={(e)=>send(e,'inquiry')} className={'btn'}>发送给客服</button>
                            </div>
                        }
                    </div>
                </div>
            )
        case "order":
            return (
                <div className={'custom-msg-wrap-box'} role={'none'} onClick={toDetail}>
                    <div className={'info-box'}>
                        <div className={'title-box'}>
                            单号：{customMsg.code_}
                        </div>
                        <div className={'tips-box'}>
                            {
                                customMsg.item_list_.map((item, index) => {
                                    return (
                                        <Fragment key={index}>【{item.product_group_name_}】{item.product_format_}；</Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            !hiddenBtn &&
                            <div className={'btn-box'}>
                                <button onClick={(e)=>send(e,'order')} className={'btn'}>发送给客服</button>
                            </div>
                        }
                    </div>
                </div>
            );
        default:
            return null
    }

};
export default CustomMsgRender
