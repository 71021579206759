function onSessions(sessions,data) {
    console.log('收到会话列表', sessions);
    data.sessions = window.nim.mergeSessions(data.sessions, sessions);
    updateSessionsUI();
}

function onUpdateSession(session,data,dispatch) {
    console.log('会话更新了', session);
    data.sessions = window.nim.mergeSessions(data.sessions, session);
    dispatch({
        type:'saveState',
        payload:{
            sessions:data.sessions
        }
    })
    updateSessionsUI();
}

function updateSessionsUI() {
    // 刷新界面
}
export {
    onSessions,
    onUpdateSession
}
