import StorageUtil from "../utils/storage-util";
import {LocalConst} from "../utils/constant";
import {ShopFetch} from "../services/service";

export default {

    namespace: 'goodsBaseModel',

    state: {
        typeList: [],
        brandList: [],
        tagList: [],
        propertyList: []
    },

    subscriptions: {
        // 初始化数据
        init({dispatch, history}) {
            dispatch({type: 'getGoodsBaseData'});
        },

    },

    effects: {
        * getGoodsBaseData({payload}, {call, put, select, all}) {
            const companyId = StorageUtil.getItem(LocalConst.companyId);
            const [typeList, brandList, tagList, propertyList] = yield all([
                call(ShopFetch.listTypeByCompany, {companyId: companyId}),
                call(ShopFetch.listBrandByCompany, {companyId: companyId}),
                call(ShopFetch.searchTagList, {companyId: companyId}),
                call(ShopFetch.getUsePropertyTree, {companyId: companyId}),
            ]);

            yield put({
                type: 'saveState',
                payload: {
                    typeList: typeList.data.children,
                    brandList: brandList.data.filter(item => item.is_view_shop_),
                    tagList: tagList.data,
                    propertyList: propertyList.data.children
                }
            });


        },
    },

    reducers: {
        saveState(state, action) {
            return {...state, ...action.payload};
        },
    },

};
