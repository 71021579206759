import React, {Fragment} from 'react';
import {connect} from "dva";
import './index.scss'
import IMChat from "../im";

@connect(state => ({...state.appModel}))
class PageFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    toDetail = cont => {
        switch (cont.content_type_) {
            case 2:
                // const newTab = window.open(cont.url_);
                break;
            case 1:
            default:
                this.props.history.push('/help/detail', {data: cont});
                break;
        }
    };

    render() {
        const {visibleChat, pageFooter = {}} = this.props;
        const {background_ = {}, height_ = 0, padding_ = {}, shop_help_ = {}, content_} = pageFooter;
        const footerStyle = {
            'backgroundColor': background_.color_,
            'backgroundPositionX': background_.horizontal_,
            'backgroundPositionY': background_.vertical_,
            'backgroundRepeat': background_.repeat_ === 'padding' ? 'no-repeat' : background_.repeat_,
            'backgroundSize': background_.repeat_ === 'padding' ? '100% 100%' : '',
            'minHeight': height_,
            'paddingTop': padding_.top_ || 0,
            'paddingLeft': padding_.left_ || 0,
            'paddingRight': padding_.right_ || 0,
            'paddingBottom': padding_.bottom_ || 0,
            'textAlign': shop_help_.is_show_ ? shop_help_.align_ : '',
        };

        if (background_.img_) {
            footerStyle['backgroundImage'] = 'url(' + background_.img_ + ')';
        }
        let shopHelpBoxStyle = {};
        if (Object.keys(shop_help_).length > 0) {
            shopHelpBoxStyle = {
                'width': shop_help_.width_ + 'px',
                'paddingTop': shop_help_.padding_.top_ + 'px',
                'paddingBottom': shop_help_.padding_.bottom_ + 'px',
            };
        }
        return (
            <Fragment>

                <div className="page-footer-box" style={footerStyle}>
                    <div className="page-wrap">
                        <div className="page-foot-layout">
                            {
                                shop_help_.is_show_ &&
                                <div className="shop-help-list-box" style={shopHelpBoxStyle}>
                                    {
                                        (shop_help_.pdList || []).map((help, index) => {
                                            return (
                                                <dl className="shop-help-list" key={index}>
                                                    <dt className="title">{help.name_}</dt>
                                                    {
                                                        help.contentList && help.contentList.length > 0 && help.contentList.filter(cont => {
                                                            return cont.is_view_shop_ === 1
                                                        }).map((cont, cIndex) => {
                                                            return (
                                                                <dd className="txt" key={cIndex} role="none" onClick={() => this.toDetail(cont)}>{cont.title_}</dd>
                                                            )
                                                        })
                                                    }
                                                </dl>
                                            )
                                        })
                                    }
                                </div>
                            }
                            <div className="foot-content">
                                <div className="editor-box" dangerouslySetInnerHTML={{__html: content_}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PageFooter
