import React, {Fragment, useState} from 'react'
import {PictureOutlined, FolderOpenOutlined} from '@ant-design/icons'
import {message} from 'antd'

const FileWrap = ({sendFunc}) => {
    const [fileType, setFileType] = useState(null);
    const moreList = [
        {
            title: '相册',
            img: <PictureOutlined/>,
            type: 'image',
        },
        {
            title: '文件',
            img: <FolderOpenOutlined/>,
            type: 'file',
            accept: '*'
        },
    ];

    const send = () => {
        try {
            window.nim.previewFile({
                scene: 'p2p',
                fileInput: `jsFile-${fileType}`,
                type: fileType === 'film' ? 'file' : fileType,
                maxSize: fileType==='file'?104857600:10485760, //单位 bytes
                uploadprogress: function (obj) {
                    console.log('文件总大小: ' + obj.total + 'bytes');
                    console.log('已经上传的大小: ' + obj.loaded + 'bytes');
                    console.log('上传进度: ' + obj.percentage);
                    console.log('上传进度文本: ' + obj.percentageText);
                },
                done: function (error, file) {
                    console.log('上传image' + (!error ? '成功' : '失败'));
                    if (!error) {
                        sendFunc({
                            scene: 'p2p',
                            file: file,
                            type: fileType === 'film' ? 'file' : fileType,
                        });
                    } else {
                        if (error.code === "File_Too_Large") {
                            message.info(`${fileType === 'file' ? '文件' : '图片'}不能超过${fileType === 'file' ? '100' : '10'}MB`)
                        }
                    }
                }
            })
        } catch (e) {
            console.log(e.message)
        }
    };

    const onChange = (e) => {
        var fileTag = e.target;
        var file = fileTag.files[0];
        if (!file) return;
        var fileReader = new FileReader();
        fileReader.onloadend = () => {
            send()
        };
        fileReader.readAsDataURL(file);
    };
    return (
        <Fragment>
            {
                moreList.map((item, index) => {
                    return (
                        <span className={'item'}
                              key={index}
                        >
                                   <input type="file"
                                          className={'file'}
                                          id={`jsFile-${item.type}`}
                                          data-type={item.type}
                                          onChange={onChange}
                                          accept={item.accept}
                                          onClick={event => {
                                              // 点击的时候记录类型
                                              event.stopPropagation();
                                              event.nativeEvent.stopImmediatePropagation();
                                              setFileType(item.type)
                                          }}
                                   />
                            {item.img}
                        </span>
                    )
                })
            }
        </Fragment>
    )
}

export default FileWrap
