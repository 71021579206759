import React, {useEffect, useRef, useCallback} from "react";
import './styles.scss'

const EditorComponent = (props) => {
    const inputDiv = useRef();
    const {emoji = {}, dispatch, sendValue = ''} = props;
    const onKeyUp = () => {
        inputDiv.current.scrollTop = inputDiv.current.scrollHeight;
        // 把信息存到 model 方便后续操作
        let str = inputDiv.current.innerHTML;
        str = str.substring(0,4999)
        dispatch({
            type: 'appModel/saveState',
            payload: {
                sendValue: str
            }
        })
    };
    useEffect(() => {
        callback()
        return inputDiv.current.removeEventListener('paste', null, false)
    }, [])
    const callback = useCallback(() => {
        inputDiv.current.addEventListener('paste', e => {

            e.stopPropagation();
            e.preventDefault();
            var text = '', event = (e.originalEvent || e);
            if (event.clipboardData && event.clipboardData.getData) {
                text = event.clipboardData.getData('text/plain');
            } else if (window.clipboardData && window.clipboardData.getData) {
                text = window.clipboardData.getData('Text');
            }
            if (document.queryCommandSupported('insertText')) {
                document.execCommand('insertText', false, text);
            } else {
                document.execCommand('paste', false, text);
            }
        }, false)
    }, [])
    useEffect(() => {
        // 监控是否有 emoji 传入
        if (emoji.key) {
            inputDiv.current.innerHTML +=
                `<img src="${emoji.img}" alt="${emoji.key}"/>`;
            onKeyUp()
        }
    }, [emoji.clickNow]);
    useEffect(() => {
        // 监控是否有 emoji 传入
        if (!sendValue) {
            inputDiv.current.innerHTML = '';
            onKeyUp()
        }
    }, [sendValue]);

    return (
        <div className={'editor-box'}>
            <div className='editor-content'
                 ref={inputDiv}
                 contentEditable
                 onKeyUp={onKeyUp}
                 role={'none'}
            />
        </div>
    )
}

export default EditorComponent
