import React, {Fragment, useState, useEffect, useCallback, useRef} from 'react'
import {connect} from 'dva'
import './styles.scss'
import {LocalConst} from "../../utils/constant";
import {CloseOutlined} from '@ant-design/icons';
import EditorComponent from "./components/editor";
import EmojiWrap from "./components/emoji";
import ChatItem from "./components/chat-item";
import FileWrap from "./components/file";
import SendMessage from "./utils/send-message";
import StorageUtil from "../../utils/storage-util";
import CustomMsgRender from "./components/custom-msg-render";
import {message, Modal} from 'antd'
import {HistoryOutlined} from '@ant-design/icons'
import HistoryChat from "./history";
import UsefulExpressions from "./components/useful-expressions";

const IMChat = (props) => {
    const {
        dispatch, imCurrentFriend,
        msgs, newSessions, myInfo,
        history, sendValue,
    } = props;
    const {account, nick, avatar} = imCurrentFriend;
    const chatCustomMsg = StorageUtil.getItem('chatCustomMsg', true);
    const [messageList, setMessageList] = useState([]);
    const [emoji, selectEmoji] = useState([]);
    const [audioPlayIndex, setAudioPlayIndex] = useState(undefined);
    const [customMsg, setCustomMsg] = useState(chatCustomMsg);
    const [visibleHistory, setToggleHistory] = useState(false);
    const scrollNode = useRef();

    useEffect(() => {
        callback();
        return () => {
            window.nim.resetCurrSession();
            StorageUtil.delItem('chatCustomMsg', true);
        }
    }, [dispatch]);


    const callback = useCallback(() => {
        window.nim.setCurrSession(`p2p-${account}`);
        if (msgs && msgs[`p2p-${account}`]) {
            window.nim.markMsgRead(msgs[`p2p-${account}`]);
            delete msgs[`p2p-${account}`];
            dispatch({
                type: 'appModel/saveState',
                payload: {
                    msgs: msgs
                }
            })
        }
        getMsgList();
    }, []);
    useEffect(() => {
        if (newSessions?.sessionId === `p2p-${account}`) {
            const list = messageList.slice();
            list.push(newSessions);
            setMessageList(list?.sort((a, b) => a.time - b.time) || []);
            dispatch({
                type: 'appModel/saveState',
                payload: {newSessions: undefined}
            })
        }
    }, [dispatch, newSessions]);

    useEffect(() => {
        genMsgListCallback()
    }, [messageList]);
    const genMsgListCallback = useCallback(() => {
        scrollNode.current.scrollTop = scrollNode.current.scrollHeight
    }, []);


    const getMsgList = (lastMsgId) => {
        // 获取历史信息，通过云库
        window.nim.getHistoryMsgs({
            scene: 'p2p',
            to: account,
            endTime: lastMsgId,
            done: (error, obj) => {
                if (!error) {
                    let newList = [...messageList, ...obj.msgs];
                    newList = newList.sort((a, b) => a.time - b.time);
                    setMessageList(newList)
                }
            }
        });
    };

    // 发送消息
    const send = (options) => {
        const sendOptions = Object.assign({}, options, {
            recipient: account,
            dispatch: dispatch,
        });
        SendMessage(sendOptions);
        dispatch({
            type: 'appModel/saveState',
            payload: {
                sendValue: ''
            }
        });
        if (customMsg) {
            closeCustomMsg();
        }
    };
    const closeCustomMsg = () => {
        setCustomMsg(null);
        StorageUtil.delItem('chatCustomMsg', true)
    };
    // 播放语音
    const audioPress = (e, url, index) => {
        // 如果存在，就停掉正在播放的,并且移除掉节点
        const $audioBox = document.querySelector('#jsAudioBox');
        if ($audioBox) {
            $audioBox.pause();
            document.body.removeChild($audioBox)
        }
        // 创建 播放 节点
        const node = document.createElement('audio');
        node.id = `jsAudioBox`;
        node.src = url;
        node.controls = true;
        node.style.display = 'none';
        document.body.appendChild(node);

        node.play();
        node.addEventListener("playing", function () {		//播放状态Doing
            setAudioPlayIndex(index)
        });
        //监听暂停状态，移除节点
        node.addEventListener("pause", () => {
            node && document.body.removeChild(node);
            setAudioPlayIndex(undefined)
        });
    };
    // 下载文件
    const downloadFile = (file) => {
        if (!file) return;
        Modal.confirm({
            title: '确认下载文件？',
            content: '',
            onOk() {
                const download = window.nim.packFileDownloadName({
                    url: file.url,
                    name: file.name
                });
                const filename = file.name;
                const aElement = document.createElement('a');
                document.body.appendChild(aElement);
                aElement.style.display = 'none';
                aElement.href = download;
                aElement.download = filename;
                aElement.click();
                document.body.removeChild(aElement);
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    };
    // 跳转自定义信息详情
    const toCustomMsgDetail = (data) => {
        switch (data.type) {
            case "inquiry":
                history.push(`/inquire/detail/${data.inquiry_sheet_id_}`);
                break;
            case "order":
                history.push(`/order/detail/${data.id_}`);
                break;
        }
    };
    // 关闭 im
    const close = () => {
        dispatch({
            type: 'appModel/saveState',
            payload: {
                visibleChat: false
            }
        })
    };
    return (
        <Fragment>
            <div className={'im-chat-modal-box'}>
                <div className="im-chat-modal-header-box">
                    <div className="user-info-box">
                        <div className="user-avatar-box">
                            <img src={avatar || LocalConst.AvatarDefault} alt=""/>
                        </div>
                        <div className="user-name-box">
                            {nick}
                        </div>
                    </div>
                    <div className="im-chat-operation-box" role={'none'} onClick={close}>
                        <span className={'item'}><CloseOutlined/></span>
                    </div>
                </div>
                <div className="im-chat-modal-body-box" ref={scrollNode}>
                    {
                        (messageList || []).map((item, index) => {

                            return (
                                <ChatItem
                                    key={item.time}
                                    data={{...item, index: index}}
                                    avatar={item.flow === 'in' ? avatar : myInfo && myInfo.avatar}
                                    onPress={audioPress}
                                    audioPlayIndex={audioPlayIndex}
                                    toCustomMsgDetail={toCustomMsgDetail}
                                    downloadFile={downloadFile}
                                />
                            )


                        })
                    }
                    <CustomMsgRender
                        customMsg={customMsg}
                        sendFunc={send}
                        toDetail={() => toCustomMsgDetail(customMsg)}
                    />
                </div>
                <div className="im-chat-modal-footer-box">
                    <div className="im-chat-tool-box">
                        <EmojiWrap
                            chooseEmoji={selectEmoji}
                            sendFunc={send}
                        />
                        <UsefulExpressions
                            sendFunc={send}
                        />
                        <FileWrap
                            sendFunc={send}
                        />
                        <span className={'item history'}
                              role={'none'}
                              onClick={() => {
                                  setToggleHistory(!visibleHistory)
                              }}
                        >
                            <HistoryOutlined/>
                        </span>
                    </div>
                    <div className="im-chat-textarea-box">
                        <EditorComponent
                            dispatch={dispatch}
                            emoji={emoji}
                            sendValue={sendValue}
                        />
                    </div>
                    <div className="im-chat-bottom-box">
                        <button className={'btn'} onClick={close}>关闭</button>
                        <button className={'btn'}
                                onClick={() => {
                                    if (!sendValue) return message.info('不能发送空消息');
                                    const options = {
                                        scene: 'p2p',
                                        value: sendValue,
                                        type: 'text',
                                    };
                                    send(options)
                                }}>
                            发送
                        </button>
                    </div>
                </div>
            </div>
            {
                visibleHistory &&
                <HistoryChat
                    {...props}
                    toCustomMsgDetail={toCustomMsgDetail}
                    downloadFile={downloadFile}
                    audioPress={audioPress}
                    audioPlayIndex={audioPlayIndex}
                    toggleHistory={setToggleHistory}
                />
            }
        </Fragment>
    )
};

export default connect(start => ({
    ...start.appModel
}))(IMChat)





