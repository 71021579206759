import {routerRedux} from "dva/router";
import {Base64} from 'js-base64';
import qs from 'qs';
import pathToRegexp from 'path-to-regexp';
import routers from '../router-config';
import CommonUtil from "../utils/common-util";
import StorageUtil from "../utils/storage-util";
import {LocalConst, SessionConst} from "../utils/constant";
import InitUtil from "../utils/init-util";
import UrlUtil from "../utils/url-config";
import {ShopFetch, SystemFetch} from "../services/service";
import {initYxSdkConf} from "../utils/yx-im";

export default {

    namespace: 'appModel',

    state: {
        homeFloors: [],
        pageHeader: {},
        pageFooter: {},
        broadside_: {},
        decorateInfo: {},
        imCurrentFriend: {}
    },

    subscriptions: {
        // 初始化数据
        init({dispatch, history}) {
            const {location} = history;
            const UA = localStorage.getItem('wkData-webUA') || window.navigator.userAgent;
            const isMobile = UA.match(/(iPhone|iPod|Android|ios)/i);
            let query = CommonUtil.getSearchFromUrl();
            //手机打开PC链接重定向至H5页面
            if (isMobile) {
                const host = window.location.host;
                let url = '';
                if (host.indexOf('.chefcp.') > -1 || host.indexOf('.dh688.') > -1 || host.indexOf('192.168.') > -1) {
                    url = UrlUtil.getMShopUrl()
                } else {
                    url = host
                }
                const pathname = location.pathname;
                url += pathname.indexOf('/goods/detail/') > -1 ? pathname : '/home';
                window.location.href = url + '?companyId=' + query.companyId;
                return
            }


            // 获取登陆用户信息
            let ysUser = StorageUtil.getItem(LocalConst.mShop_user);
            const companyTheme = StorageUtil.getItem(LocalConst.companyTheme);
            let companyId = StorageUtil.getItem(LocalConst.companyId);

            //不同公司id,清除本地缓存
            if (query.companyId && (+query.companyId !== companyId)) {
                localStorage.clear();
                companyId = +query.companyId;
                // 更新企业id
                StorageUtil.setItem(LocalConst.companyId, +query.companyId);
            }
            // fix:CDD-2711 本地url都不存在id，默认设置1
            if (!companyId && !query.companyId) {
                StorageUtil.setItem(LocalConst.companyId, 1);
            }
            // webapp登陆跳转
            if (query.auth_code_) {
                const auth_code_ = JSON.parse(Base64.decode(decodeURIComponent(query.auth_code_)));
                ysUser = auth_code_;
                // 本地保存登录信息
                CommonUtil.saveStorageFormLogin(auth_code_);
                dispatch(routerRedux.replace({
                    pathname: location.pathname,
                    search: qs.stringify({
                        companyId: companyId,
                        // CDD-2833
                        referrer: query.referrer
                    })
                }));
            }
            // 分享人
            if (query.u) {
                StorageUtil.setItem(LocalConst.shareUserId, query.u, true)
            }
            //分享客户
            if (query.c) {
                StorageUtil.setItem(LocalConst.shareClientId, query.c, true)
            }

            // 设置主题色
            if (companyTheme || (ysUser && ysUser.company_theme_)) {
                CommonUtil.setTheme(companyTheme || (ysUser && ysUser.company_theme_));
            }
            // 不存在companyId，跳转登陆
            if (!companyId) {
                // Toast.info(`缺少企业id,${window.location.href}`);
                return;
            }
            // 判断是否体验企业
            const isExperience = !!CommonUtil.checkExperience(companyId);
            // 设置体验账号信息
            StorageUtil.setItem(LocalConst.isExperience, isExperience);

            // 体验账号,没有登陆人或者登录人id不等于url新id
            if (isExperience && (!ysUser || ysUser.company_id_ !== companyId)) {
                // 自动登陆体验账号，获取公司设置信息
                CommonUtil.toShopExperience();
            }

            dispatch({type: 'getPageDataByWeb', payload: companyId});
            // 获取公司详细信息
            InitUtil.getCompany(companyId).then(rs => {
                if (rs.status === 1) {
                    if (!rs.data.allow_visitor_ && !isExperience && !ysUser) {
                        // 非体验账号不允许游客访问，退出登陆端
                        CommonUtil.logout();
                        return;
                    }

                    StorageUtil.setItem(LocalConst.companyName, rs.data.name_);
                    StorageUtil.setItem(LocalConst.companyTheme, rs.data.theme_);
                    document.title = rs.data.shop_home_title_ || rs.data.name_;

                    //设置主题色
                    CommonUtil.setTheme(rs.data.theme_);
                    //设置浏览器小icon
                    CommonUtil.setIco(rs.data.mini_web_icon_);
                    // 创建统计代码
                    CommonUtil.setStatisticalCode(rs.data.view_count_url_);
                }
            });
            // 获取企业应用
            InitUtil.getApplicationStatus(companyId);
            // 商城设置信息
            InitUtil.getShopCompanySetting(companyId);
            // 注册设置
            InitUtil.getShopRegisterSetting(companyId);

            // 获取购物车数量
            if (ysUser && !query.code) {
                InitUtil.getCartNumber();
                InitUtil.getClientInfo();
                InitUtil.getSubsidiaryById(ysUser.subsidiary_id_);
                // 获取当前登录客户的云信帐号
                // 登录云信
                InitUtil.getByFkIdAndType({id: ysUser.client_id_, type: 3}).then(rs => {
                    if (rs.status && rs.data)
                        initYxSdkConf({
                            options: {account: rs.data.acc_id_, token: rs.data.token_},
                            subsidiary_id_: ysUser.subsidiary_id_,
                            dispatch
                        });
                    // initYxSdkConf({options: {account: 'zhanghao2', token: '123456'}, dispatch});
                })


            }
            // 删除微信设置信息
            StorageUtil.delItem(LocalConst.wechatData);
        },

        listen({history, dispatch}) {
            // 监听 history 变化，记录上一个路由
            return history.listen((location) => {
                const query = qs.parse(location.search, {ignoreQueryPrefix: true});
                const companyId = StorageUtil.getItem(LocalConst.companyId);
                const localHistory = StorageUtil.getItem(SessionConst.localHistory, true) || [];
                const historyLen = localHistory.length;
                // 两次路由相同 splice
                if (historyLen > 0 && location.pathname === localHistory[historyLen - 1].pathname) {
                    localHistory.splice(historyLen - 1, 1, location)
                } else {
                    localHistory.push(location);
                }
                if (historyLen > 12) {
                    localHistory.shift()
                }
                // fix:CDD-1369
                document.body.scrollTop = 0;
                StorageUtil.setItem(SessionConst.localHistory, localHistory, true);
                if (!query.companyId && companyId) {
                    console.log(query);
                    query.companyId = companyId;
                    location.search = qs.stringify(query);
                    // history.replace(location);
                } else {
                    // 添加访问记录
                    const routerPro = routers.find(router => pathToRegexp(router.path).exec(location.pathname));
                    if (!query.auth_code_) {
                        SystemFetch.visitLog({
                            url_: window.location.href,
                            title_: routerPro.title,
                        });
                    }
                }
            });
        }
    },

    effects: {
        * getPageDataByWeb({payload}, {call, put, select}) {
            const rs = yield call(ShopFetch.getPageDataByPc, {company_id_: payload});
            // 查询该企业是否需要应用装修
            if (rs.status === 1) {
                const decorateInfo = rs.data;
                const header_ = JSON.parse(decorateInfo.header_);
                const footer_ = JSON.parse(decorateInfo.footer_);
                const floors_ = JSON.parse(decorateInfo.floors_);
                const broadside_ = CommonUtil.stringToObj(decorateInfo.broadside_);
                const background_ = JSON.parse(decorateInfo.background_);
                header_.navigation_.buttons_.forEach(item => {
                    item.newLocation = CommonUtil.getDecorateLocation(item.target_, true)
                });
                yield put({
                    type: 'saveState',
                    payload: {
                        decorateInfo: decorateInfo,
                        homeFloors: floors_,
                        pageHeader: header_,
                        pageFooter: footer_,
                        broadside_: broadside_
                    }
                });
                if (header_.style_url_ && header_.style_url_.length > 0 && header_.style_url_[0].url) {
                    //设置自定义样式表
                    CommonUtil.setCssFile(header_.style_url_[0].url)
                }
                if (Object.keys(background_).length > 0) {
                    CommonUtil.setBackground(background_)
                }

                let contentArr = [];
                floors_.forEach((item, pIndex) => {
                    if (item.content_ && item.content_.length > 0) {
                        contentArr.push({
                            content: item.content_,
                            pIndex: pIndex,
                        })
                    }
                    if (item.content_1_ && item.content_1_.length > 0) {
                        contentArr.push({
                            content: item.content_1_,
                            pIndex: pIndex,
                        })
                    }
                    if (item.content_2_ && item.content_2_.length > 0) {
                        contentArr.push({
                            content: item.content_2_,
                            pIndex: pIndex,
                        })
                    }
                });
                // 查询商品楼层
                while (contentArr.length) {
                    yield put({type: 'handleContent', payload: contentArr[0]});
                    contentArr.splice(0, 1)
                }

                if (footer_.shop_help_ && footer_.shop_help_.help_list_) {
                    yield put({type: 'getShopHelp', payload: footer_.shop_help_.help_list_})
                }
            }
        },
        * handleContent({payload}, {call, put, select}) {
            const companyId = StorageUtil.getItem(LocalConst.companyId);
            const {content, pIndex} = payload;
            let goodsFloorArr = [];
            content.forEach((item, index) => {
                if (item.type_ === 2) {
                    const {row_size_, list_style_, goods_source_type_, goods_source_val_, goods_sort_} = item.setting_;
                    if (goods_source_type_) {
                        goodsFloorArr.push({
                            content: content,
                            pIndex: pIndex,
                            index: index,
                            params: {
                                company_id_: companyId,
                                goods_num_: (row_size_ || 1) * (list_style_ || 1),// 整数相乘,不用考虑精度
                                goods_source_type_: goods_source_type_ || '',
                                goods_source_val_: goods_source_val_ || '',
                                goods_sort_: goods_sort_ || '',
                            }
                        });
                    }
                }
            });

            // 查询商品楼层
            while (goodsFloorArr.length) {
                yield put({type: 'searchListForDecorate', payload: goodsFloorArr[0]});
                goodsFloorArr.splice(0, 1)
            }
        },
        * searchListForDecorate({payload}, {call, put, select}) {
            let state = yield select(state => state.appModel);
            const {homeFloors} = state;
            const {content, pIndex, index, params} = payload;
            let {list_style_, row_size_ = 1/*YSDH-7250:商城装修商品列表旧数据兼容处理,旧数据没有设置行数，默认为2*/} = content[index].setting_;
            const rs = yield call(ShopFetch.searchListForDecorate, params);
            if (rs.status) {
                const pdList = rs.data.pdList;
                (pdList || []).forEach(goods => {
                    if (goods.tag_ids_) {
                        let ids = (goods.tag_ids_ + '').split(',');
                        let names = (goods.tag_names_ + '').split(',');
                        let colors = (goods.tag_colors_ + '').split(',');
                        goods.tagList = [];
                        for (let i = 0, j = ids.length; i < j; i++) {
                            goods.tagList.push({name: names[i], id: ids[i], color: colors[i]});
                        }
                    }
                    if (goods.goods_promotion_types_) {
                        goods.goodsPromotionTypes = goods.goods_promotion_types_.split(',')
                    }
                });
                let noteArr = [];
                for (let a = 0, b = row_size_; a < b; a++) {
                    const subGoodsList = (pdList || []).slice(list_style_ * a, list_style_ * (a + 1));
                    if (subGoodsList.length > 0) {
                        let subNoteArr = [];
                        for (let i = 0, j = subGoodsList.length; i < j; i++) {
                            const item = subGoodsList[i];
                            subNoteArr.push(item);
                        }
                        if (subNoteArr.length < list_style_) {
                            // 如果子note数量不足,则填满到goods_num_的数量
                            for (let x = 0, y = list_style_ - subNoteArr.length; x < y; x++) {
                                subNoteArr.push(null);
                            }
                        }
                        noteArr[a] = subNoteArr
                    }
                }
                if (homeFloors[pIndex].content_ && homeFloors[pIndex].content_.length > 0) {
                    homeFloors[pIndex].content_[index].pdList = noteArr;
                }
                if (homeFloors[pIndex].content_1_ && homeFloors[pIndex].content_1_.length > 0 && homeFloors[pIndex].content_1_[index] && homeFloors[pIndex].content_1_[index].type_ === 2) {
                    homeFloors[pIndex].content_1_[index].pdList = noteArr;
                }
                if (homeFloors[pIndex].content_2_ && homeFloors[pIndex].content_2_.length > 0 && homeFloors[pIndex].content_2_[index] && homeFloors[pIndex].content_2_[index].type_ === 2) {
                    homeFloors[pIndex].content_2_[index].pdList = noteArr;
                }
                yield put({
                    type: 'saveState',
                    payload: {
                        homeFloors: homeFloors
                    }
                })
            } else {
                console.log(rs.message)
            }
        },
        * getShopHelp({payload}, {call, put, select}) {
            let state = yield select(state => state.appModel);
            const companyId = StorageUtil.getItem(LocalConst.companyId);
            const {pageFooter} = state;
            const rs = yield call(SystemFetch.shopHelpTypeSearchList, {company_id_: companyId, showCount: 99999999})
            if (rs.status) {
                let newList = [];
                const shopHelpList = rs.data.pdList;
                shopHelpList.forEach(item => {
                    payload.forEach(help => {
                        if (item.id_ === help.id_) {
                            let obj = item;
                            obj.seq_ = help.seq_;
                            obj.hidden_ = help.hidden_;
                            newList.push(obj)
                        }
                    })
                });
                newList = newList.sort((a, b) => {
                    return a.seq_ - b.seq_
                });
                newList = newList.filter(item => !item.hidden_);
                pageFooter.shop_help_.pdList = newList;

                yield put({
                    type: 'saveState',
                    payload: {
                        pageFooter: {...pageFooter}
                    }
                })
            }
        },
    },

    reducers: {
        saveState(state, action) {
            return {...state, ...action.payload};
        },
    },

};
